import React, { Component } from 'react';
import './index.css'
import Home from './component/Home';
import Category from './component/Category';
import NavBar from './component/Nav';
import Footer from './component/Footer';
import DiscountApply from './component/Discount';
import ProductList from './component/productdi';
import ClientTable from './component/ClientTable';
import Banner from './component/Banner';
import Login from './component/Login';
import Test from './component/Test';
import Services from './component/Services';
import Sellers from './component/Sellers';
import SellersBuyersOrders from './component/SellersBuyersOrders';
import BuyerOrders from './component/BuyerOrdes';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import Buyers from './component/Buyers';
import SellersProducts from './component/SellersProducts';
import AdminPortals from './component/AdminPortals';
import AdminUserTypes from './component/AdminUserTypes';
import AdminUserTypeRights from './component/AdminUserTypeRights';
import AdminUsers from './component/AdminUserCreation';
import SystemConfig from './component/SystemConfig';
import Feedback from './component/Feedback';
import SellerserviceApprove from './component/SellerserviceApprove';
import Query from './component/Query';
import SRRRReport from './component/SRRRReport';
import BuyerCommission from './component/BuyerCommission';
import SellerPayments from './component/SellerPayment';
import BankReports from './component/Reports/BankReports';
import LogisticsReports from './component/Reports/LogisticsReports';
import PaymentReports from './component/Reports/PaymentReports';
import ReceiptReports from './component/Reports/ReceiptReports';
import ServiceReports from './component/Reports/ServiceReports';
import TdsReports from './component/Reports/TdsReports';
import VendorReports from './component/Reports/VendorReports';
import EmailTemplates from './component/emailTemplate';
import Pincodes from './component/Pincodes';

const common = require('./utils/Common');

const WithNavFoot = () => {
  return(
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}

const WithoutNavFoot = () => {
  return(
    <Outlet />
  );
}

class App extends Component{
  render(){
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<WithoutNavFoot />}>
            <Route path='login' exact element={<Login/>}/>
          </Route>
          <Route element={<WithNavFoot />}>
            <Route path='/' element={<Home/>}/>
            <Route path='category' element={<Category/>}/>
            <Route path='discount-apply' element={<DiscountApply/>}/>
            <Route path='product-list' element={<ProductList/>}/>
            <Route path='client-table' element={<ClientTable/>}/>
            <Route path='cms' element={<Banner/>}/>
            <Route path='test' element={<Test/>}/>
            <Route path='service' element={<Services/>}/>
            <Route path='sellers' element={<Sellers />} />
            <Route path='buyers' element={<Buyers />} />
            <Route path='sellers-buyers-orders' element={<SellersBuyersOrders />} />
            <Route path='buyer-orders' element={<BuyerOrders />} />
            <Route path='sellers-products' element={<SellersProducts />} />
            <Route path='portals' element={<AdminPortals/>}/>
            <Route path='user-types' element={<AdminUserTypes/>}/>
            <Route path='user-type-rights/:user_type_id' element={<AdminUserTypeRights/>}/>
            <Route path='admin-user-creation' element={<AdminUsers/>}/>
            <Route path='system-config' element={<SystemConfig/>}/>
            <Route path='user-feedback' element={<Feedback/>}/>
            <Route path='user-query' element={<Query/>}/>
            <Route path='seller-service-approve' element={<SellerserviceApprove/>}/>
            <Route path='Srrr-report' element={<SRRRReport/>}/>
            <Route path='buyer-commissions' element={<BuyerCommission/>}/>
            <Route path='seller-payments' element={<SellerPayments/>}/>
            <Route path='BankReports' element={<BankReports/>}/>
            <Route path='LogisticsReports' element={<LogisticsReports/>}/>
            <Route path='PaymentReports' element={<PaymentReports/>}/>
            <Route path='ReceiptReports' element={<ReceiptReports/>}/>
            <Route path='ServiceReports' element={<ServiceReports/>}/>
            <Route path='TdsReports' element={<TdsReports/>}/>
            <Route path='VendorReports' element={<VendorReports/>}/>
            <Route path='email-templates' element={<EmailTemplates/>}/>
            <Route path='pincodes' element={<Pincodes/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App;
