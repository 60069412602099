import React, { useEffect, useState ,useMemo} from 'react';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SellersProducts = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [allProducts, setAllProducts] = useState([]);
    console.log(allProducts)
    const [subProducts, setSubProducts] = useState([]);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

	useEffect(() => {
        if (!location.state) {
            navigate('/sellers-products');
            return;
        }
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            // setAllProducts(location.state);
            getProducts()
        }
    }

    const showSubProducts = (subProducts) => {
        setSubProducts(subProducts);
        toggle();
    }

    const approveProduct = async (product_id) => {
        let data = {
            'id': product_id,
        }
        let response = await common.apiRequest('post', '/admin/approve-product', data);
        if (response.data.code == 'success') {
            alert(response.data.message);
            getProducts();

        } else {
            alert(response.data.error);
        }
    }

    const getProducts = async () => {
        let data = {
            'seller_id': location.state.seller_id,
            'action': "read",
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/get-products', data);
        if (response.data.code == 'success') {
            setAllProducts(response.data.data);
        } else {
            setAllProducts([]);
        }
    }
    const deleteProdcut = async (product,sellerId) => {
        var parameters = {
            'id': product,
            'seller_id': sellerId,
            'action': 'delete',
        };        
        let response = await common.apiRequest('post', '/seller/get-products', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                alert(response.data.message);
            } else {
                alert(response.data.error, 'error');
            }
            getProducts();
        }
        getProducts()
    }
    const data = allProducts.map((product) => ({
        "Product Name": product.name,
        "Product Description": product.description,
        "Product IDs": product.product_items[0]?.id,
        "Product SKUs": product.product_items[0]?.product_sku,
        "Product Images": product.product_items[0]?.product_image,
        "Price": product.product_items[0]?.price, 
        "HSN Code": product.product_items[0]?.hsn_code,
        "Item Weight": product.product_items[0]?.item_weight,
        "Item Weight After Pack": product.product_items[0]?.after_pack_item_weight,
        "After Sale Services": product.product_items[0]?.after_sale_services,
        "FAQ": product.product_items[0]?.faq,
        "GST Percent": product.product_items[0]?.gst_percent,
        "Legal Disclaimer": product.product_items[0]?.legal_disclaimer,
        "Qty In Stock": product.product_items[0]?.qty_in_stock,
        "Supply GST No": product.product_items[0]?.supply_gst_no,
        "Supply Pincode": product.product_items[0]?.supply_pincode,
        "Supply State": product.product_items[0]?.supply_state,
        "Taxable Value": product.product_items[0]?.taxable_value,
        "Warranty or Guarantee": product.product_items[0]?.warranty_or_gurantee
    }));
    
    
    const handleExportCSV = () => {
        exportToCSV(data, 'products');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'products');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'products');
    }
    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { headerName: "Product Name", field: "name" },
        { headerName: "Product Date", field: "created_at" },
        { headerName: "Product Description", field: "description" },
        { headerName: "Product ID", valueGetter: params => params.data.product_items[0]?.id },
        { headerName: "Product SKU", valueGetter: params => params.data.product_items[0]?.product_sku },
        { 
            headerName: "Product Image", 
            cellRenderer: params => {
                const images = params.data.product_items[0]?.product_image;
                if (!images) return '';
                
                return (
                    <div className="d-flex justify-content-center mb-3">
                        {images.split(',').map((image, index) => (
                            <Zoom key={index} zoomImg={false}>
                                <img 
                                    width="60" 
                                    height="60" 
                                    className="rounded-2" 
                                    src={`${envConfig.api_url}${image}`} 
                                />
                            </Zoom>
                        ))}
                    </div>
                );
            }
        },
        { headerName: "Price", valueGetter: params => params.data.product_items[0]?.price },
        { headerName: "HSN Code", valueGetter: params => params.data.product_items[0]?.hsn_code },
        { headerName: "Item Weight", valueGetter: params => params.data.product_items[0]?.item_weight },
        { headerName: "Item Weight After Pack", valueGetter: params => params.data.product_items[0]?.after_pack_item_weight },
        { headerName: "After Sale Services", valueGetter: params => params.data.product_items[0]?.after_sale_services },
        { headerName: "FAQ", valueGetter: params => params.data.product_items[0]?.faq },
        { headerName: "GST Percent", valueGetter: params => params.data.product_items[0]?.gst_percent },
        { headerName: "Legal Disclaimer", valueGetter: params => params.data.product_items[0]?.legal_disclaimer },
        { headerName: "Qty In Stock", valueGetter: params => params.data.product_items[0]?.qty_in_stock },
        { headerName: "Taxable Value", valueGetter: params => params.data.product_items[0]?.taxable_value },
        { headerName: "Warranty/Guarantee", valueGetter: params => params.data.product_items[0]?.warranty_or_gurantee },
        {
            headerName: "Pincodes",
            cellRenderer: params => (
                params.data.pincodes ? 
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.pincodes)}>
                    View Pincodes
                </button>
                : "This product is not self deliverable"
            )
        },
        // { 
        //     headerName: "Sub Products", 
        //     field: "subProducts", 
        //     cellRenderer: params => (
        //         <button 
        //             className="btn btn-primary btn-sm" 
        //             onClick={() => showSubProducts(params.data.product_items)}
        //         >
        //             View
        //         </button>
        //     )
        // },
        { 
            headerName: "Approve Products Status", 
            cellRenderer: params => {
                const active = params.data.product_items[0]?.active;
                const productId = params.data.product_items[0]?.id;
                
                return active == 1 ? 
                    "Approved" : 
                    <button 
                        className='btn btn-primary btn-sm' 
                        onClick={() => approveProduct(productId)}
                    >
                        Approve
                    </button>
            }
        },
        {
            headerName: "Delete Products",
            cellRenderer: (params) => {
                const sellerId = params.data.seller_id;
                const productId = params.data.product_items[0]?.id;
                return (
                    <div>
                        <button
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => deleteProdcut(productId,sellerId)}
                        >
                            Delete
                        </button>
                    </div>
                );
            }
        }
        
    ], [allProducts]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []); 
    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }
    const viewPincodes = async (pincodes) => {
        pincodes = pincodes.split('--');
        pincodes[0] = pincodes[0].replaceAll('-', '');
        pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
        let data = {
            'pincodes': pincodes,
        }
        let response = await common.apiRequest('post', '/seller/get-pincodes', data);
        if (response.data.code === 'success') {
            togglePincodeModal();
            setPincodes(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }
    return (
        <>
        <h1>All Products</h1><br/>
        <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getProducts} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                        <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getProducts} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                    </FormGroup>
                </Form>
                <div>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
        {/* <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Product Name</th>
                    <th>Product Description</th>
                    <th>Sub Products</th>
                    <th>Approve Products status</th>
                </tr>
            </thead>
            <tbody>
                {
                    allProducts.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="2">
                                <center>No Products Available</center>
                            </td>
                        </tr>
                    :
                    allProducts.map((productData) => {
                        return (
                            <>
                                <tr>
                                    <td>{productData.name}</td>
                                    <td>{productData.description}</td>
                                    <td><button className='btn btn-primary btn-sm' onClick={() => showSubProducts(productData.product_items)}>View</button></td>
                                    <td>{productData.product_items[0].active == 1 ? "Approved" :"Not approved"}</td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table> */}
          <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                <AgGridReact
                    rowData={allProducts}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
        {/* <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Sub Products</ModalHeader>
            <ModalBody>
                <table class="seller-table table-responsive">
                <thead>
                        <tr>
                            <th>Product ID</th>
                            <th>Product SKU</th>
                            <th>Product Image</th>
                            <th>Price</th>
                            <th>HSN Code</th>
                            <th>Item weight</th>
                            <th>Item weight after pack</th>
                            <th>after sale services</th>
                            <th>faq</th>
                            <th>gst percent</th>
                            <th>legal_disclaimer</th>
                            <th>qty_in_stock</th>
                            <th>supply_gst_no</th>
                            <th>supply_pincode</th>
                            <th>supply_state</th>
                            <th>taxable_value</th>
                            <th>warranty_or_gurantee</th>
                            <th>Approve Status</th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                    {
                        subProducts.map((product) => {
                            return (
                                <tr>
                                    <td>{product.id}</td>
                                    <td>{product.product_sku}</td>
                                    <td>
                                    {
                                            product.product_image ? 
                                                product.product_image.split(',').map((image) => {
                                                    return (
                                                        <img src={envConfig.api_url + image} alt="image" style={{ height: '100px', width: '100px' }} />
                                                    );
                                                }) 
                                            : ""
                                        }

                                    </td>
                                    <td>{product.price}</td>
                                    <td>{product.hsn_code}</td>
                                    <td>{product.item_weight}</td>
                                    <td>{product.after_pack_item_weight}</td>
                                    <td>{product.after_sale_services}</td>
                                    <td>{product.faq}</td>
                                    <td>{product.gst_percent}</td>
                                    <td>{product.legal_disclaimer}</td>
                                    <td>{product.qty_in_stock}</td>
                                    <td>{product.supply_gst_no}</td>
                                    <td>{product.supply_state}</td>
                                    <td><button className='btn btn-primary' onClick={() => viewPincodes(product.pincodes)}>View Pincodes</button></td>
                                    <td>{product.taxable_value}</td>
                                    <td>{product.warranty_or_gurantee}</td>
                                    <td>
                                        {
                                            product.active == 1 ? "Approved" : <button className='btn btn-primary' onClick={() => approveProduct(product.id)}>Approve</button>  
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
        </table>
            </ModalBody>
        </Modal> */}
        <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                    <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                    <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {
                            pincodes.map((pincode) => {
                                return (
                                    <>
                                        {pincode.pincode}&nbsp;&nbsp;
                                    </>
                                );
                            })
                        }
                    </ModalBody>      
                </Modal>
        </>
    )
}

export default SellersProducts;