import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import $ from 'jquery';
import moment from 'moment';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
// import { consoleLogger } from '../../../API/utils/logger';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Buyers = () => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [orderDetails, setOrderDetails] = useState([]);
    const [modal, setModal] = useState(false);
    const [userRefferData, setuserRefferData] = useState([]);
    const [showRefferModal, setShowRefferModal] = useState(false);
    const [buyersData, setBuyersData] = useState([]);
    const [referralPayment, setReferralPayment] = useState(0);
    const [commissionOperationDate, setCommissionOperationDate] = useState({
        "from": null,
        "to": null
    });
    const [distribution, setDistribution] = useState([]);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [commissionAmountSeller, setCommissionAmountSeller] = useState(0);
    const [buyerId, setBuyerId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const parsedData = typeof userRefferData === 'string'
        ? userRefferData.split('|').map(item => {
            const [name, referSale, referBuy] = item.split(':');
            return { name, referSale: parseFloat(referSale), referBuy: parseFloat(referBuy) };
        })
        : [];
    const getBuyers = async () => {
        let data = {
            "user_type": 'buyer',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
        if (response.status == 200) {
            if (response.data.code == 'success') {
                setBuyersData(response.data.data);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    }

    const movePage = (path, data) => {
        navigate(path, { state: data });
    }

    const getBuyerCommissionConfig = async () => {
        let response = await common.getSystemConfig('buyer_commission_config');
        if (response) {
            let value = response['type'];
            setDistribution(response["distribution"]);
            if (value == 'monthly') {
                var date = new Date();
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                if (date == lastDay) {
                    setCommissionOperationDate({
                        'from': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' 00:00:00',
                        'to': lastDay.getFullYear() + '-' + (lastDay.getMonth() < 10 ? '0' + lastDay.getMonth() : lastDay.getMonth()) + '-' + (lastDay.getDate() < 10 ? '0' + lastDay.getDate() : lastDay.getDate()) + ' 23:59:59',
                    });
                    setReferralPayment(1);
                }
            } else if (value == 'daily') {
                var date = new Date();
                setCommissionOperationDate({
                    'from': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' 00:00:00',
                    'to': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' 23:59:59',
                });
                setReferralPayment(1);
            }
        }
    }

    const calculateReferralPaymentAmount = async (userId, type) => {
        var data;
        if (type == 'buyer') {
            data = {
                'buyer_id': userId,
                'operation_date': commissionOperationDate,
                'distribution': distribution
            }
        } else if (type == 'seller') {
            data = {
                'seller_id': userId,
                'user_type': 'seller',
                'operation_date': commissionOperationDate
            }
        } else {
            alert('Invalid Operation');
            return;
        }
        let response = await common.apiRequest('post', '/seller/get-referral-payment-amount', data);
        if (response.status == 200) {
            if (response.data.code == 'success') {
                if (response.data.data == 0) {
                    alert('Nothing to pay');
                    return;
                }
                if (type == 'buyer') {
                    setCommissionAmount(response.data.data);
                } else {
                    setCommissionAmountSeller(response.data.data);
                }
                setBuyerId(userId);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    }
    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };
    const handleShowRefferModal = async(userData) => {
        let data = {
            "user_id": userData,
        }
        let response = await common.apiRequest('post', '/admin/fetch-referrer-data-by-user', data);
        if (response.status == 200) {
            if (response.data.code == 'success') {
                setuserRefferData(response.data.data);
                setShowRefferModal(true);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
        // setuserRefferData(userData);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };
    const handleCloserefferModal = () => {
        setShowRefferModal(false);
        setSelectedUser(null);
    };
    const makePayment = async (buyerId, type) => {
        let data = {
            'action': 'create',
            'buyer_id': buyerId,
            'payment_amount': type == 'buyer' ? commissionAmount : commissionAmountSeller,
            'type': type,
            'payment_status': 'done'
        }
        let response = await common.apiRequest('post', '/seller/crud-buyer-payments', data);
        if (response.status == 200) {
            if (response.data.code == 'success') {
                alert(response.data.message);
                window.location.href = "/buyers";
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    }

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            getBuyerCommissionConfig();
            getBuyers();
        }
    }
    const handleAccept = async (id) => {
        const data = {
            action: "update",
            id: id,
            pan_status: "1",
            "user_type": 'buyer'
        };
        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } handleCloseModal();
        handleCloseModal();
    };

    const handleReject = async (id) => {
        const data = {
            action: "update",
            id: id,
            pan_status: "0",
            "user_type": 'buyer'
        };
        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } handleCloseModal();
    };
    const handleActive = async (user) => {
        const data = {
            action: "updateActive",
            id: user.id,
            'active': user.active ? 0 : 1,
            "user_type": 'buyer'
        };

        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getBuyers()
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const handleEditShow = (data) => {
        setEditData(data);
        setEditModal(true);
    };

    const handleEditClose = () => {
        setEditModal(false);
        setEditData(null);
    };
    const addDetail = async (id) => {
        var parameters = {
            action: "updateNomini",
            "id": id,
            "user_type": 'buyer',
            pan_no: $('#pan_no').val(),
            first_name: $('#first_name').val(),
            last_name: $('#last_name').val(),
            email: $('#email').val(),
            mobile_phone: $('#mobile_phone').val(),
            bank_name: $('#bank_name').val(),
            bank_account_no: $('#bank_account_no').val(),
            ifsc_code: $('#ifsc_code').val(),
        };
        let response = await common.apiRequest('post', '/admin/get-sellers-users-data', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                alert(response.data.message);
                handleEditClose();
                getBuyers();
            } else {
                alert(response.data.error, 'error');
            }
        }
    }
    const [columnDefs] = useState([
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { field: 'reg_id', headerName: 'User Id', filter: 'agTextColumnFilter' },
        { field: 'first_name', headerName: 'First Name', filter: 'agTextColumnFilter' },
        { field: 'middle_name', headerName: 'Middle Name', filter: 'agTextColumnFilter' },
        { field: 'last_name', headerName: 'Last Name', filter: 'agTextColumnFilter' },
        { field: 'seller_business_name', headerName: 'Service Vendor Business Name', filter: 'agTextColumnFilter' },
        { field: 'pan_no', headerName: 'PAN No', filter: 'agTextColumnFilter' },
        { field: 'gst_no', headerName: 'GST No', filter: 'agTextColumnFilter' },
        { field: 'totalPurchases', headerName: 'Total Shopping Amount', filter: 'agTextColumnFilter' },
        // { field: 'totalTaxablePurchases', headerName: 'Total Taxable Shopping Amount', filter: 'agTextColumnFilter' },
        { field: 'totalSales', headerName: 'Total Sales Amount', filter: 'agTextColumnFilter' },
        // { field: 'totalTaxableSales', headerName: 'Total Taxable Amount', filter: 'agTextColumnFilter' },
        { field: 'email', headerName: 'Email', filter: 'agTextColumnFilter' },
        { field: 'mobile_phone', headerName: 'Mobile Phone', filter: 'agTextColumnFilter' },
        { field: 'bank_account_no', headerName: 'Bank Account No', filter: 'agTextColumnFilter' },
        { field: 'bank_name', headerName: 'Bank Name', filter: 'agTextColumnFilter' },
        { field: 'ifsc_code', headerName: 'IFSC Code', filter: 'agTextColumnFilter' },
        { field: 'nominee_name', headerName: 'Nominee Name', filter: 'agTextColumnFilter' },
        { field: 'referrerBY', headerName: 'Referred By', filter: 'agTextColumnFilter' },
        { field: 'user_address', headerName: 'Address', filter: 'agTextColumnFilter' },
        { field: 'seller_Point_Contact', headerName: 'Point of Contact', filter: 'agTextColumnFilter' },
        {
            headerName: 'referred User data',
            cellRenderer: params => (
                <Button color="primary" onClick={() => handleShowRefferModal(params.data.id)}>
                    View reffer
                </Button>
            )
        },
        // { field: 'commesion', headerName: 'Commesion', filter: 'agTextColumnFilter' },
        {
            headerName: 'Orders',
            cellRenderer: params => (
                <Button color="primary" size="sm" onClick={() => movePage('/buyer-orders', params.data.id)}>
                    All Orders
                </Button>
            )
        },
        {
            headerName: 'Pan image',
            cellRenderer: params => (
                <Button color="primary" onClick={() => handleShowModal(params.data)}>
                    View Image
                </Button>
            )
        },
        { field: 'active', headerName: 'Active Status', valueGetter: params => params.data.active ? "Active" : "Not Active" },
        {
            headerName: 'Active/Inactive',
            cellRenderer: params => (
                <Button onClick={() => handleActive(params.data)} className='submit'>
                    {params.data.active ? "Deactivate" : "Activate"}
                </Button>
            )
        },
        // {
        //     headerName: 'Nomination',
        //     cellRenderer: params => (
        //         <Button onClick={() => handleEditShow(params.data)} className='submit'>Change Nomination details</Button>
        //     )
        // }
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
    }), []);
    const data = buyersData.map((item, index) => ({
        "S.no": index + 1,
        "User Id": item.reg_id || "N/A",  
        "First Name": item.first_name || "N/A",  
        "Middle Name": item.middle_name || "N/A",
        "Last Name": item.last_name || "N/A",  
        "Service Vendor Business Name": item.seller_business_name || "N/A",  
        "PAN No": item.pan_no || "N/A",  
        "GST No": item.gst_no || "N/A",  
        "Total Shopping Amount": item.totalPurchases || 0,  
        "Total Sales Amount": item.totalSales || 0,  
        "Email": item.email || "N/A",  
        "Mobile Phone": item.mobile_phone || "N/A",  
        "Bank Account No": item.bank_account_no || "N/A",  
        "Bank Name": item.bank_name || "N/A",  
        "IFSC Code": item.ifsc_code || "N/A",  
        "Nominee Name": item.nominee_name || "N/A",  
        "Referred By": item.referrerBY || "N/A",  
        "Address": item.user_address || "N/A",  
        "Point of Contact": item.seller_Point_Contact || "N/A",  
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'buyer');
}

const handleExportExcel = () => {
    exportToExcel(data, 'buyer');
}

const handleExportPDF = () => {
    exportToPDF(data, 'buyer');
}
const formatRefferDataForExport = (userRefferData) => {
    return userRefferData.map((user, index) => ({
        "S. No.": index + 1,
        "Name": user.first_name || "N/A",
        "Total Order Amount Offline": user.total_order_amount_offline || 0,
        "Total Order Amount Online": user.total_order_amount_online || 0,
        "Total Order Amount Service": user.total_order_amount_service || 0,
        "Total Taxable Amount Offline": user.total_taxable_amount_offline || 0,
        "Total Taxable Amount Online": user.total_taxable_amount_online || 0,
        "Total Taxable Amount Service": user.total_taxable_amount_service || 0
    }));
};

const formatOrderDetailsForExport = (orderDetails) => {
    return orderDetails.map((data, index) => ({
        "S.No": index + 1,
        "Order Id": data.order?.order_id || 'NA',
        "Invoice No": data.order?.invoices 
            ? (data.order.invoices.find(invoice => 
                invoice.invoice_type === 'seller_product' && 
                invoice.seller_id === data.seller_id
              ) || { invoice_no: "" }).invoice_no 
            : "",
        "Product Name": data.product_item?.product.name || 
                       data.offline_sale_product_datum?.product_description || 
                       data.seller_service?.service_name || 
                       'NA',
        "Online Product Sale Amount": data.product_id ? data.order_price : 0,
        "Online Product Sale Taxable Amount": data.product_id ? data.taxable_amount : 0,
        "Logistics Amount": data.logistics_order ? data.logistics_order.order_price : 0,
        "Logistics Taxable Amount": data.logistics_order ? data.logistics_order.taxable_value : 0
    }));
};

// Modify the export handler functions for the reffer modal
const handleExportCSVs = () => {
    const exportData = formatRefferDataForExport(userRefferData);
    exportToCSV(exportData, 'reffer_data');
}

const handleExportExcels = () => {
    const exportData = formatRefferDataForExport(userRefferData);
    exportToExcel(exportData, 'reffer_data');
}

const handleExportPDFs = () => {
    const exportData = formatRefferDataForExport(userRefferData);
    exportToPDF(exportData, 'reffer_data');
}

// Add new export handler functions for the order details modal
const handleOrderDetailsExportCSV = () => {
    const exportData = formatOrderDetailsForExport(orderDetails);
    exportToCSV(exportData, 'order_details');
}

const handleOrderDetailsExportExcel = () => {
    const exportData = formatOrderDetailsForExport(orderDetails);
    exportToExcel(exportData, 'order_details');
}

const handleOrderDetailsExportPDF = () => {
    const exportData = formatOrderDetailsForExport(orderDetails);
    exportToPDF(exportData, 'order_details');
}
const getOrderDetails = async (orderIds) => {
    let data = {
        'ids': orderIds
    }
    let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
    if (response.status == 200) {
        if (response.data.code == 'success') {
            setOrderDetails(response.data.data);
            setModal(true);
        } else {
            common.alert(response.data.error, "error");
        }
    } else {
        common.alert(response.data.error, "error");
    }
}
const toggle = () => {
    setModal(!modal);
    setOrderDetails([]);
}
const [refferColumnDefs] = useState([
    { field: 'first_name', headerName: 'Name', filter: 'agTextColumnFilter' },
    { field: 'total_order_amount_offline', headerName: 'Total Order Amount Offline', filter: 'agNumberColumnFilter' },
    { field: 'total_order_amount_online', headerName: 'Total Order Amount Online', filter: 'agNumberColumnFilter' },
    { field: 'total_order_amount_service', headerName: 'Total Order Amount Service', filter: 'agNumberColumnFilter' },
    { field: 'total_taxable_amount_offline', headerName: 'Total Taxable Amount Offline', filter: 'agNumberColumnFilter' },
    { field: 'total_taxable_amount_online', headerName: 'Total Taxable Amount Online', filter: 'agNumberColumnFilter' },
    { field: 'total_taxable_amount_service', headerName: 'Total Taxable Amount Service', filter: 'agNumberColumnFilter' },
    {
        headerName: 'More Information',
        cellRenderer: params => (
            <Button 
                color="primary" 
                size="sm" 
                onClick={() => getOrderDetails(params.data.order_item_ids)}
            >
                View
            </Button>
        )
    }
]);

const [orderDetailsColumnDefs] = useState([
    { headerName: "S.No", valueGetter: "node.rowIndex + 1" },
    { field: 'order_id', headerName: 'Order Id', valueGetter: params => 
        params.data.order && params.data.order.order_id ? params.data.order.order_id : 'NA' 
    },
    { 
        field: 'invoice_no', 
        headerName: 'Invoice No',
        valueGetter: params => {
            if (params.data.order && params.data.order.invoices) {
                const invoice = params.data.order.invoices.find(
                    inv => inv.invoice_type === 'seller_product' && inv.seller_id === params.data.seller_id
                );
                return invoice ? invoice.invoice_no : "";
            }
            return "";
        }
    },
    { 
        field: 'product_name', 
        headerName: 'Product Name',
        valueGetter: params => 
            params.data.product_item?.product.name || 
            params.data.offline_sale_product_datum?.product_description || 
            params.data.seller_service?.service_name || 
            'NA'
    },
    { 
        field: 'online_product_sale_amount', 
        headerName: 'Online Product Sale Amount',
        valueGetter: params => params.data.product_id ? params.data.order_price : 0
    },
    { 
        field: 'online_product_sale_taxable_amount', 
        headerName: 'Online Product Sale Taxable Amount',
        valueGetter: params => params.data.product_id ? params.data.taxable_amount : 0
    },
    { 
        field: 'logistics_amount', 
        headerName: 'Logistics Amount',
        valueGetter: params => params.data.logistics_order ? params.data.logistics_order.order_price : 0
    },
    { 
        field: 'logistics_taxable_amount', 
        headerName: 'Logistics Taxable Amount',
        valueGetter: params => params.data.logistics_order ? params.data.logistics_order.taxable_value : 0
    }
]);
    return (
        <>
            <h1>All Buyers</h1><br />
            <div className="col-md-12">
                <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getBuyers} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                        <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getBuyers} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                    </FormGroup>
                </Form>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>
            <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={buyersData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            <Modal isOpen={showModal} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>User PAN Image</ModalHeader>
                <ModalBody>
                    {selectedUser && selectedUser.user_pan_image && (
                        <img
                            src={envConfig.api_url + selectedUser.user_pan_image}
                            style={{ width: '100%', height: 'auto' }}
                            alt="User PAN"
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => handleAccept(selectedUser.id)}>
                        Accept
                    </Button>
                    <Button color="danger" onClick={() => handleReject(selectedUser.id)}>
                        Reject
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={editModal} toggle={handleEditClose}>
                <ModalHeader toggle={handleEditClose}>
                    Select here to change
                </ModalHeader>
                <ModalBody>
                    <Form>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>PAN No</Form.Label>
                            <Form.Control type="text" name="pan_no" defaultValue={editData?.pan_no || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" name="first_name" id='first_name' defaultValue={editData?.first_name || ''} />
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" name="last_name" id='last_name' defaultValue={editData?.last_name || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" id='email' defaultValue={editData?.email || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile Phone</Form.Label>
                            <Form.Control type="tel" name="mobile_phone" id='mobile_phone' defaultValue={editData?.mobile_phone || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control type="text" name="bank_name" id='bank_name' defaultValue={editData?.bank_name || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Bank Account No</Form.Label>
                            <Form.Control type="text" name="bank_account_no" id='bank_account_no' defaultValue={editData?.bank_account_no || ''} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Bank IFSC</Form.Label>
                            <Form.Control type="text" name="ifsc_code" id='ifsc_code' defaultValue={editData?.ifsc_code || ''} />
                        </Form.Group> */} 
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => addDetail(editData.id)}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>




            <Modal isOpen={showRefferModal} toggle={handleCloserefferModal}>
                <ModalHeader toggle={handleCloserefferModal}>reffer data</ModalHeader>
                <ModalBody>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <div>
                <div className="mb-3">
                    <button className='btn button-product me-2' onClick={handleExportCSVs}>Export to CSV</button>
                    <button className='btn button-product me-2' onClick={handleExportExcels}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDFs}>Export to PDF</button>
                </div>
                <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={userRefferData}
                        columnDefs={refferColumnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                    />
                </div>
            </div>
                    

                    )}

                </ModalBody>
            </Modal>
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <div>
            <div className="mb-3">
                <button className='btn button-product me-2' onClick={handleOrderDetailsExportCSV}>Export to CSV</button>
                <button className='btn button-product me-2' onClick={handleOrderDetailsExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleOrderDetailsExportPDF}>Export to PDF</button>
            </div>
            <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    rowData={orderDetails}
                    columnDefs={orderDetailsColumnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
        </div>

                </ModalBody>
            </Modal>
        </>
    )
}

export default Buyers;