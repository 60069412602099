import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellerserviceApprove = () => {
    const location = useLocation();
    let navigate = useNavigate();
    
    const [servicesData, setServicesData] = useState([]);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            getServices();
        }
    };

    const getServices = async () => {
        const data = {
            action: "read",
            source: "admin",
            user_type: "seller",
            'seller_id': location.state.seller_id,
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/admin/get-sellers-service-data', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                // Filter services based on location.state.rowType
                const filteredData = response.data.data.filter(service => 
                    location.state.rowType === 'logistics' 
                        ? service.service_type === 'logistics'
                        : service.service_type === 'normal'
                );
                setServicesData(filteredData);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    };

    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }

    const viewPincodes = async (pincodes) => {
        if (pincodes) {
            pincodes = pincodes.split('--');
            pincodes[0] = pincodes[0].replaceAll('-', '');
            pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
            let data = {
                'pincodes': pincodes,
            }
            let response = await common.apiRequest('post', '/seller/get-pincodes', data);
            if (response.data.code === 'success') {
                togglePincodeModal();
                setPincodes(response.data.data);
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    }

    const approveProduct = async (product_id) => {
        let data = {
            'id': product_id,
            "active": 1,
            "action": "update"
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            setServicesData(prevData => 
                prevData.map(service => 
                    service.id === product_id ? { ...service, active: 1 } : service
                )
            );
            alert(response.data.message);
        } else {
            alert(response.data.error);
        }
    };

    const serviceColumnDefs = useMemo(() => [
        { headerName: "Service Name", field: "service_name" },
        { headerName: "Parent Category", field: "parent_category.category_name" },
        { headerName: "Sub Category", field: "sub_category.category_name" },
        { headerName: "Service Date", field: "created_at" },
        { headerName: "Service Type", field: "service_type" },
        {
            headerName: "Service Image",
            field: "service_image",
            cellRenderer: (params) => (
                params.value ? <img src={envConfig.api_url + params.value} style={{ height: '100px', width: '100px' }} alt="Service" /> : ''
            )
        },
        { headerName: "Service Description", field: "service_description" },
        { headerName: "Service Price", field: "price" },
        { headerName: "Service SKU", field: "service_sku" },
        { headerName: "Service By", field: "service_by" },
        { headerName: "GST Rate", field: "gst_rate" },
        { headerName: "Taxable Value", field: "taxable_value" },
        ...(location.state.rowType === 'logistics' ? [
            { headerName: "Logistics Delivery Type", field: "logistics_delivery_type" },
            { headerName: "Range In Grams From", field: "range_in_grams_from" },
            { headerName: "Range In Grams To", field: "range_in_grams_to" },
            { headerName: "Zone A", field: "zone_a" },
            { headerName: "Zone B", field: "zone_b" },
            { headerName: "Zone C", field: "zone_c" },
            { headerName: "Zone D", field: "zone_d" },
            { headerName: "Zone E", field: "zone_e" },
            { headerName: "Zone F", field: "zone_f" },
            { headerName: "Minimum Delivery Time", field: "min_delivery_time" },
            { headerName: "Maximum Delivery Time", field: "max_delivery_time" },
            { headerName: "Additional Grams", field: "additional_grams" },
            { headerName: "Additional Price Add In Every Zone", field: "additional_price_add_in_every_zone" },
        ] : []),
        {
            headerName: "Pincodes",
            cellRenderer: (params) => (
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.seller_service_pincodes.length > 0 ? params.data.seller_service_pincodes[0].pincode : null)}>
                    View Pincodes
                </button>
            )
        },
        {
            headerName: "Actions",
            cellRenderer: (params) => {
                return (
                    params.data.active ? "Approved" : (
                        <Button color="success" size="sm" onClick={() => approveProduct(params.data.id)}>
                            Approve Service
                        </Button>
                    )
                );
            }
        }
    ], [location.state.rowType]);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
    }), []);

    const exportData = servicesData.map((service) => ({
        "Service Name": service.service_name,
        "Parent Category": service.parent_category?.category_name || '',
        "Sub Category": service.sub_category?.category_name || '',
        "Service Date": service.created_at,
        "Service Type": service.service_type,
        "Service Description": service.service_description,
        "Service Price": service.price,
        "Service SKU": service.service_sku,
        "Service By": service.service_by,
        "GST Rate": service.gst_rate,
        "Taxable Value": service.taxable_value,
        ...(location.state.rowType === 'logistics' ? {
            "Logistics Delivery Type": service.logistics_delivery_type,
            "Range In Grams From": service.range_in_grams_from,
            "Range In Grams To": service.range_in_grams_to,
            "Zone A": service.zone_a,
            "Zone B": service.zone_b,
            "Zone C": service.zone_c,
            "Zone D": service.zone_d,
            "Zone E": service.zone_e,
            "Zone F": service.zone_f,
            "Minimum Delivery Time": service.min_delivery_time,
            "Maximum Delivery Time": service.max_delivery_time,
            "Additional Grams": service.additional_grams,
            "Additional Price Add In Every Zone": service.additional_price_add_in_every_zone,
        } : {})
    }));

    const handleExportCSV = () => {
        exportToCSV(exportData, 'service');
    }

    const handleExportExcel = () => {
        exportToExcel(exportData, 'service');
    }

    const handleExportPDF = () => {
        exportToPDF(exportData, 'service');
    }

    return (
        <>
            <h1>{location.state.rowType === 'logistics' ? 'Logistics Services' : 'Normal Services'}</h1>
            <Form className='row'>
                <FormGroup className='col-md-4 row'>
                    <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                    <Input 
                        type='date' 
                        defaultValue={moment().startOf('month').format('YYYY-MM-DD')} 
                        onChange={getServices} 
                        className="form-control col-md-5" 
                        id="Date-from" 
                        name="Date-from"
                    />
                </FormGroup>
                <FormGroup className='col-md-4 row'>
                    <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                    <Input 
                        type='date' 
                        defaultValue={moment().endOf('month').format('YYYY-MM-DD')} 
                        onChange={getServices} 
                        className="form-control col-md-5" 
                        id="Date-to" 
                        name="Date-to"
                    />
                </FormGroup>
            </Form>
            
            <div>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>
            
            <div className="mt-4">
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        rowData={servicesData}
                        columnDefs={serviceColumnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </div>

            <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <div style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                        {pincodes.map((pincode) => (
                            <div key={pincode.pincode} style={{ marginBottom: '10px' }}>
                                {pincode.pincode}
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default SellerserviceApprove;