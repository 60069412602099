import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { formatHeaderName } from '../../utils/formatHeaderName';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

export default function ServiceReports() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const { CSVDownloader } = useCSVDownloader();
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getReportData();
    }
}
  const getReportData = async () => {
    var parameters = {
      'source':'Admin',
      'report_name': 'service_reports',
      'start_date': $('#Date-from').val(),
      'end_date': $('#Date-to').val(),
    };
    let response = await common.apiRequest('post', '/reports/get-reports', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];

    // Set column definitions
    const cols = [
      { 
        field: 'slNo', 
        headerName: 'Sl No.', 
        valueGetter: (params) => params.node.rowIndex + 1,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 100
      },
      ...data.keys.map(key => ({
        field: key,
        headerName: formatHeaderName(key),
        filter: 'agTextColumnFilter',
        editable: true
      }))
    ];
    setColumnDefs(cols);

    // Set row data
    setRowData(data.reportData);
  }

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("TDS Report", 40, 40);
    
    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));
    
    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("tds_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: true,
  }), []);
  
  const data = rowData.map(row => {
    const newRow = {};
    Object.keys(row).forEach(key => {
      newRow[formatHeaderName(key)] = row[key];
    });
    return newRow;
  });
  const handleExportCSV = () => {
    exportToCSV(data, 'Service report');
}

const handleExportExcel = () => {
    exportToExcel(data, 'Service report');
}

const handleExportPDF = () => {
    exportToPDF(data, 'Service report');
}
  return (
    <>
      <div className="row report-margin">
        <div className='col-md-10'>
        <h3>service Reports</h3>
        </div>
        <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
        <div className="col-md-12 button-container">
        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      </div>
    </>
  )
}