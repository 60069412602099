import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

export default function TdsReports() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bsrCode, setBsrCode] = useState('');
  const [challanNo, setChallanNo] = useState('');
  const [challanDate, setChallanDate] = useState('');
  const [rateOfDeduction, setRateOfDeduction] = useState('');
  const [dateOfDeduction, setDateOfDeduction] = useState('');

  const { CSVDownloader } = useCSVDownloader();
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      dataTDS();
    }
  };

  const dataTDS = async () => {
    let data = { 'action': 'admin' ,
    'start_date': $('#Date-from').val(),
    'end_date': $('#Date-to').val()
  };
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', data);
    if (response.status === 200) {
      if (response.data.code === 'success') {
        setRowData(response.data.data); 
      } else {
        alert(response.data.error);
      }
    } else {
      alert(response.data.error);
    }
  };
  useEffect(() => {
    const columns = [
      {
        headerName: "Select",
        field: "select",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 50
      },
      { headerName: "User ID", field: "user.reg_id" },
      { headerName: "PAN No", field: "user.pan_no" },  
      {
        headerName: "User Name as per PAN Card", 
        valueGetter: (params) => 
          `${params.data.user?.first_name || ''} ${params.data.user?.middle_name || ''} ${params.data.user?.last_name || ''}`
      },
      { headerName: "Taxable Amount", field: "taxable_value" },
      { headerName: "Commission Rate", field: "commission" },
      { headerName: "Commission Amount", field: "commission"},
      { headerName: "Date of Payment", field: "created_at" },
      { headerName: "Amount Of TDS", field: "tds" },
      { headerName: "Date Of Deduction", field: "date_of_deduction" },
      { headerName: "Rate Of Deduction", field: "rate_of_deduction" },
      { headerName: "Section", field: "section" },
      { headerName: "Challan No", field: "challan_no" },
      { headerName: "Challan Date", field: "challan_date" },
      { headerName: "BSR Code", field: "bsr_code" },
      { headerName: 'Order ID', field: 'order_item.order.order_id', sortable: true, filter: true }
    ];

    setColumnDefs(columns);
  }, []);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    const ids = selectedRows.map(row => row.id);
    const updateData = {
        bsr_code: bsrCode,
        challan_no: challanNo,
        challan_date: challanDate,
        rate_of_deduction: rateOfDeduction,
        date_of_deduction: dateOfDeduction,
    };

    let data = {
        action: 'adminupdate',
        ids: ids, 
        update: updateData
    };
    
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', data);
    if (response.status === 200 && response.data.code === 'success') {
        alert(response.data.message);
        setIsModalOpen(false);
        setSelectedRows([]);
        dataTDS();
    } else {
        alert(response.data.error || 'Failed to update the data');
    }
};

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("TDS Report", 40, 40);

    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));

    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("tds_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  }), []);

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    setSelectedRows(selectedData);
  };
  const formatHeaderName = (key) => {
    return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  };
  
  const TDSdata = rowData.map((data, index) => ({
    "User ID": data.user?.reg_id,
    "PAN No": data.user?.pan_no,
    "User Name as per PAN Card": `${data.user?.first_name || ''} ${data.user?.middle_name || ''} ${data.user?.last_name || ''}`,
    "Taxable Amount": data.taxable_value,
    "Commission Rate": data.commission,
    "Commission Amount": (parseFloat(data.commission) / 100 * parseFloat(data.taxable_value)).toFixed(2),
    "Date of Payment": data.created_at,
    "Amount Of TDS": data.tds,
    "Date Of Deduction": data.date_of_deduction,
    "Rate Of Deduction": data.rate_of_deduction,
    "Section": data.section,
    "Challan No": data.challan_no,
    "Challan Date": data.challan_date,
    "BSR Code": data.bsr_code,
    'Order ID': data.order_item.order.order_id
  }));
  const data = TDSdata.map(row => {
    const newRow = {};
    Object.keys(row).forEach(key => {
      newRow[formatHeaderName(key)] = row[key];
    });
    return newRow;
  });
  const handleExportCSV = () => {
    exportToCSV(data, 'TDS report');
}

const handleExportExcel = () => {
    exportToExcel(data, 'TDS report');
}

const handleExportPDF = () => {
    exportToPDF(data, 'TDS report');
}
  return (
    <>
      <div className="row report-margin">
        <div className='col-md-8'>
          <h3>TDS Reports</h3>
        </div>
        <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={dataTDS} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={dataTDS} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
        <div className="col-md-4 button-container">
        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

          {selectedRows.length > 0 && (
            <button className="btn btn-primary" onClick={openModal}>Edit Selected</button>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <div className="modal-header">
          <h5 className="modal-title">Edit Details for {selectedRows.length} rows</h5>
          <button type="button" className="close" onClick={() => setIsModalOpen(false)}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="form-group">
              <label>BSR Code</label>
              <input 
                type="text" 
                className="form-control" 
                value={bsrCode} 
                onChange={(e) => setBsrCode(e.target.value)} 
                placeholder="Enter BSR Code"
              />
            </div>
            <div className="form-group">
              <label>Challan No</label>
              <input 
                type="text" 
                className="form-control" 
                value={challanNo} 
                onChange={(e) => setChallanNo(e.target.value)} 
                placeholder="Enter Challan No"
              />
            </div>
            <div className="form-group">
              <label>Challan Date</label>
              <input 
                type="date" 
                className="form-control" 
                value={challanDate} 
                onChange={(e) => setChallanDate(e.target.value)} 
                placeholder="Select Challan Date"
              />
            </div>
            <div className="form-group">
              <label>Rate of Deduction</label>
              <input 
                type="text" 
                className="form-control" 
                value={rateOfDeduction} 
                onChange={(e) => setRateOfDeduction(e.target.value)} 
                placeholder="Enter Rate of Deduction"
              />
            </div>
            <div className="form-group">
              <label>Date of Deduction</label>
              <input 
                type="date" 
                className="form-control" 
                value={dateOfDeduction} 
                onChange={(e) => setDateOfDeduction(e.target.value)} 
                placeholder="Select Date of Deduction"
              />
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={handleUpdate}>Submit</Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </div>
      </Modal>
    </>
  );
}
