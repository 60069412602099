import React, { useEffect } from 'react';
import './login.css';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

const common = require('../utils/Common');

const Login = () => {
    let navigate = useNavigate();
	const LoginUser = async (e) => {
		e.preventDefault();
		let data = {
			"email" : $('#email').val(),
			"password" : $('#password').val(),
		}
		if ($('#email').val() == '' || $('#password').val() == '') {
			$('#error').html('Please fill both email and password');
			return;
		}
		let response = await common.apiRequest('post', '/admin/login-user', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
				localStorage.setItem('token', response.data.access_token);
				localStorage.setItem('userId', response.data.id);
				navigate('/');
				
			} else {
				$('#error').html(response.data.error);
			}
		} else {
			$('#error').html(response);
		}
	}
	const loginFormStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/image/Adminback.jpeg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
       
    };
	useEffect(() => {
		if (localStorage.getItem('token')) {
			// props.changeLoginStatusAction(true);
			navigate('/');
		}
	}, []);
	
    return (
        <>
        <div className="login-form" style={loginFormStyle}>
                <div class="login-form-box">
                    <div class="login-form-header">
                        <h2>Admin Panel - Sign In</h2>
                    </div>
                    <form onSubmit={LoginUser} action="">
                        <div class="input-item">
                            <input type="email" placeholder="Enter Email" id="email"/>
                        </div>
                        <div class="input-item">
                            <input type="password" placeholder="Enter Password" id="password"/>
                        </div>
                        <div class="remember-pass">
                            <input type="checkbox" id="remember"/>
                            <label for="html">Remember me</label>
                        </div>
                        <p className="text-danger" id="error"></p>
                        <button className='buttonl'>Submit</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login