import React, { useEffect,useState } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useCSVDownloader } from 'react-papaparse';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { parseValue } from '../utils/Common';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const BuyerCommission = () => {
    let navigate = useNavigate();
    const { CSVDownloader } = useCSVDownloader();

    const [userCommissions, setUserCommissions] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [approveButton, setApproveButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [commissionPercentOnlineProduct, setCommissionPercentOnlineProduct] = useState(0);
    const [commissionPercentOfflineProduct, setCommissionPercentOfflineProduct] = useState(0);
    const [commissionPercentService, setCommissionPercentService] = useState(0);
    const [orderDetailsType, setOrderDetailsType] = useState(null);
    const [commissionType, setCommissionType] = useState('sale_retail');
    const [allChecked, setAllChecked] = useState(0);

    useEffect(()=>{
        checkAccess();
    },[])

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        }
    }
    
	const fetchUserCommissions = async () => {
        if (!$('#start_date').val() || !$('#end_date').val() || !$('#commission_type').val()) {
            alert("Fill all filters (Start Date, End Date & Commission Type)");
            return;
        }
        setCommissionType($('#commission_type').val());
        const data = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            fetch_approved: $('#fetch_approved').is(':checked'),
            retainer_commission: $('#commission_type').val() == "retainer" ? 1 : 0
        }
		let response = await common.apiRequest('post', '/admin/fetch-user-commission', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setUserCommissions(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    
    const addToPaymentList = (params) => {
        let list = [];
        const selectedRows = params.api.getSelectedRows();
        
        if (selectedRows.length > 0) {
            list = selectedRows;
            setApproveButton(true);
            $('#start_date').prop('disabled', true);
            $('#end_date').prop('disabled', true);
            $('#commission_type').prop('disabled', true);
            $('#fetch_approved').prop('disabled', true);
            $('#submit_button').prop('disabled', true);
        } else {
            setApproveButton(false);
            $('#start_date').prop('disabled', false);
            $('#end_date').prop('disabled', false);
            $('#commission_type').prop('disabled', false);
            $('#fetch_approved').prop('disabled', false);
            $('#submit_button').prop('disabled', false);
        }
        
        setPaymentList(list);
        setAllChecked(selectedRows.length === params.api.getDisplayedRowCount());
    };
    
    const approvePayment = async () => {
        let finalData = {
            "action": "create",
            "commission_type": $('#commission_type').val(),
            "data": paymentList
        }
        let response = await common.apiRequest('post', '/admin/crud-buyer-payment', finalData);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                setApproveButton(false);
                setPaymentList([]);
                setModal(false);
                setAllChecked(0);
                $('input[type="checkbox"]').prop('checked', false);
                $('#start_date').prop('disabled', false);
                $('#end_date').prop('disabled', false);
                $('#commission_type').prop('disabled', false);
                $('#fetch_approved').prop('disabled', false);
                $('#submit_button').prop('disabled', false);
                fetchUserCommissions();
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
        setCommissionPercentOnlineProduct(0);
        setCommissionPercentOfflineProduct(0);
        setCommissionPercentService(0);
        setOrderDetailsType(null);
    }

    const getOrderDetails = async (orderIds, commissionPercentOnlineProduct, commissionPercentOfflineProduct, commissionPercentService, type) => {
        let data = {
            'ids': orderIds,
        }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
                setCommissionPercentOnlineProduct(commissionPercentOnlineProduct);
                setCommissionPercentOfflineProduct(commissionPercentOfflineProduct);
                setCommissionPercentService(commissionPercentService);
                setOrderDetailsType(type);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }
    const retainerColumnDefsdata = userCommissions.map((item, index) => ({
        "S.No": index + 1,
        reg_id: item.reg_id,
        first_name: item.first_name,
        last_name: item.last_name,
        retainer_order_amount_online: parseValue(item.retainer_order_amount_online, 'float'),
        retainer_taxable_order_amount_online: parseValue(item.retainer_taxable_order_amount_online, 'float'),
        retainer_commission_percent_online: parseValue(item.retainer_commission_percent_online, 'float'),
        retainer_commission_online: parseValue(item.retainer_commission_online, 'float'),
        retainer_order_amount_offline: parseValue(item.retainer_order_amount_offline, 'float'),
        retainer_taxable_order_amount_offline: parseValue(item.retainer_taxable_order_amount_offline, 'float'),
        retainer_commission_percent_offline: parseValue(item.retainer_commission_percent_offline, 'float'),
        retainer_commission_offline: parseValue(item.retainer_commission_offline, 'float'),
        retainer_order_amount_service: parseValue(item.retainer_order_amount_service, 'float'),
        retainer_taxable_order_amount_service: parseValue(item.retainer_taxable_order_amount_service, 'float'),
        retainer_commission_percent_service: parseValue(item.retainer_commission_percent_service, 'float'),
        retainer_commission_service: parseValue(item.retainer_commission_service, 'float'),
        total_commission: parseValue(
            (
                parseFloat(item.retainer_commission_online || 0) +
                parseFloat(item.retainer_commission_offline || 0) +
                parseFloat(item.retainer_commission_service || 0)
            ).toFixed(2),
            'float'
        ),
        tds: parseValue(
            (
                (
                    parseFloat(item.retainer_commission_online || 0) +
                    parseFloat(item.retainer_commission_offline || 0) +
                    parseFloat(item.retainer_commission_service || 0)
                ) * 0.10
            ).toFixed(2),
            'float'
        ),
        receivable_commission: parseValue(
            (
                parseFloat(item.retainer_commission_online || 0) +
                parseFloat(item.retainer_commission_offline || 0) +
                parseFloat(item.retainer_commission_service || 0) -
                (
                    (
                        parseFloat(item.retainer_commission_online || 0) +
                        parseFloat(item.retainer_commission_offline || 0) +
                        parseFloat(item.retainer_commission_service || 0)
                    ) * 0.10
                )
            ).toFixed(2),
            'float'
        )
    }));
    
    const saleRetailColumnDefsdata = userCommissions.map((user, index) => ({
        "S.No": index + 1,
        reg_id: user.reg_id || "",
        first_name: user.first_name || "",
        middle_name: user.middle_name || "",
        last_name: user.last_name || "",
        total_user_shopping_amount_taxable: parseValue(user.total_user_shopping_amount_taxable, 'float'),
        variant: user.variant || "",
        total_shopping_online_product: parseValue(user.total_shopping_online_product, 'float'),
        total_taxable_shopping_online_product: parseValue(user.total_taxable_shopping_online_product, 'float'),
        commission_percent_online_product: parseValue(user.commission_percent_online_product, 'float'),
        total_shopping_offline_product: parseValue(user.total_shopping_offline_product, 'float'),
        total_taxable_shopping_offline_product: parseValue(user.total_taxable_shopping_offline_product, 'float'),
        commission_percent_offline_product: parseValue(user.commission_percent_offline_product, 'float'),
        total_shopping_service: parseValue(user.total_shopping_service, 'float'),
        total_taxable_shopping_service: parseValue(user.total_taxable_shopping_service, 'float'),
        commission_percent_service: parseValue(user.commission_percent_service, 'float'),
        ref_user_reg_id: user.ref_user_reg_id || "",
        total_order_amount_shopping_online_product: parseValue(user.total_order_amount_shopping_online_product, 'float'),
        total_taxable_amount_shopping_online_product: parseValue(user.total_taxable_amount_shopping_online_product, 'float'),
        commission_shopping_online_product: parseValue(user.commission_shopping_online_product, 'float'),
        total_order_amount_shopping_offline_product: parseValue(user.total_order_amount_shopping_offline_product, 'float'),
        total_taxable_amount_shopping_offline_product: parseValue(user.total_taxable_amount_shopping_offline_product, 'float'),
        commission_shopping_offline_product: parseValue(user.commission_shopping_offline_product, 'float'),
        total_order_amount_shopping_service: parseValue(user.total_order_amount_shopping_service, 'float'),
        total_taxable_amount_shopping_service: parseValue(user.total_taxable_amount_shopping_service, 'float'),
        commission_shopping_service: parseValue(user.commission_shopping_service, 'float'),
        TDS_Shopping: parseValue(
            (
                (parseFloat(user.commission_shopping_online_product) || 0) +
                (parseFloat(user.commission_shopping_offline_product) || 0) +
                (parseFloat(user.commission_shopping_service) || 0)
            ) * 0.10, 
            'float'
        ),
        Receiveable_Shopping: parseValue(
            (
                (parseFloat(user.commission_shopping_online_product) || 0) +
                (parseFloat(user.commission_shopping_offline_product) || 0) +
                (parseFloat(user.commission_shopping_service) || 0) -
                (
                    ((parseFloat(user.commission_shopping_online_product) || 0) +
                    (parseFloat(user.commission_shopping_offline_product) || 0) +
                    (parseFloat(user.commission_shopping_service) || 0)) * 0.10
                )
            ).toFixed(2), 
            'float'
        ),
        total_order_amount_retail_online_product: parseValue(user.total_order_amount_retail_online_product, 'float'),
        total_taxable_amount_retail_online_product: parseValue(user.total_taxable_amount_retail_online_product, 'float'),
        commission_percent_retail_online_product: parseValue(user.commission_percent_retail_online_product, 'float'),
        commission_retail_online_product: parseValue(user.commission_retail_online_product, 'float'),
        total_order_amount_retail_offline_product: parseValue(user.total_order_amount_retail_offline_product, 'float'),
        total_taxable_amount_retail_offline_product: parseValue(user.total_taxable_amount_retail_offline_product, 'float'),
        commission_percent_retail_offline_product: parseValue(user.commission_percent_retail_offline_product, 'float'),
        commission_retail_offline_product: parseValue(user.commission_retail_offline_product, 'float'),
        total_order_amount_retail_service: parseValue(user.total_order_amount_retail_service, 'float'),
        total_taxable_amount_retail_service: parseValue(user.total_taxable_amount_retail_service, 'float'),
        commission_percent_retail_service: parseValue(user.commission_percent_retail_service, 'float'),
        commission_retail_service: parseValue(user.commission_retail_service, 'float'),
        TDS_Retail: parseValue(
            (
                (parseFloat(user.commission_retail_online_product) || 0) +
                (parseFloat(user.commission_retail_offline_product) || 0) +
                (parseFloat(user.commission_retail_service) || 0)
            ) * 0.10, 
            'float'
        ),
        Receiveable_Retail: parseValue(
            (
                (parseFloat(user.commission_retail_online_product) || 0) +
                (parseFloat(user.commission_retail_offline_product) || 0) +
                (parseFloat(user.commission_retail_service) || 0) -
                (
                    ((parseFloat(user.commission_retail_online_product) || 0) +
                    (parseFloat(user.commission_retail_offline_product) || 0) +
                    (parseFloat(user.commission_retail_service) || 0)) * 0.10
                )
            ).toFixed(2), 
            'float'
        ),
    }));

    {console.log(userCommissions.data)}

    
    // const handleExportCSV = () => {
    //     exportToCSV(data, commissionType === "retainer"?"retainer buyer commission":"sale/Retail buyer commission");
    // }
    
    // const handleExportExcel = () => {
    //     exportToExcel(data, commissionType === "retainer"?"retainer buyer commission":"sale/Retail buyer commission");
    // }
    
    // const handleExportPDF = () => {
    //     exportToPDF(data, commissionType === "retainer"?"retainer buyer commission":"sale/Retail buyer commission");
    // }
    const rowHeight = 80;

    const dataOther = orderDetails.map((data, index) => {
        
        return {
            "S.No": index + 1,
            "Order Id": data.order?.order_id || 'NA',
            "Order Date": data.order?.created_at || 'NA',
            "Invoice No": data.order?.invoices?.find(invoice => invoice.invoice_type === (orderDetailsType === "shopping" || orderDetailsType === "retainer" ? 'product' : 'seller_product'))?.invoice_no || 'NA',
            "Product Name": data.product_item?.product?.name || data.offline_sale_product_datum?.product_description || data.seller_service?.service_name || 'NA',
            "Online Product Sale Amount": data.product_id ? data.order_price : 0,
            "Online Product Sale Taxable Amount": data.product_id ? data.taxable_amount : 0,
            "Commission Percent Online Product Sale": commissionPercentOnlineProduct,
            "Offline Product Sale Amount": data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.sale_amount : 0,
            "Offline Product Sale Taxable Amount": data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.taxable_supply : 0,
            "Commission Percent Offline Product Sale": commissionPercentOfflineProduct,
            "Service Sale Amount": data.service_id ? data.order_price : 0,
            "Service Sale Taxable Amount": data.service_id ? Number(data.taxable_amount) : 0,
            "Commission Percent Service Sale": commissionPercentService,
            "Total Commission": data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100,
            "TDS (10%)": (data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10).toFixed(2),
            "Net Receivable": (data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 - data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10).toFixed(2),
            
        };
    });
    const handleExportCSVs = () => {
        exportToCSV(dataOther, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details");
    }
    
    const handleExportExcels = () => {
        exportToExcel(dataOther, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details");
    }
    
    const handleExportPDFs = () => {
        exportToPDF(dataOther, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details");
    }
    const getMainColumnDefs = () => {
        const baseColumns = [
            {
                headerName: '',
                field: 'checkbox',
                width: 50,
                checkboxSelection: !$('#fetch_approved').is(':checked'),
                headerCheckboxSelection: !$('#fetch_approved').is(':checked'),
                headerCheckboxSelectionFilteredOnly: true
            },
            { field: 'reg_id', headerName: 'Reg Id', sortable: true, filter: true },
            { field: 'first_name', headerName: 'First Name', sortable: true, filter: true },
            { field: 'middle_name', headerName: 'Middle Name', sortable: true, filter: true },
            { field: 'last_name', headerName: 'Last Name', sortable: true, filter: true }
        ];

        if (commissionType === "retainer") {
            return [...baseColumns,
                {
                    field: 'retainer_commission_percent_online',
                    headerName: 'Commission Percent Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_percent_online, 'float')
                  },
                  {
                    field: 'retainer_commission_online',
                    headerName: 'Commission Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_online, 'float')
                  },
                  {
                    field: 'retainer_order_amount_offline',
                    headerName: 'Total Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_order_amount_offline, 'float')
                  },
                  {
                    field: 'retainer_taxable_order_amount_offline',
                    headerName: 'Total Taxable Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_taxable_order_amount_offline, 'float')
                  },
                  {
                    field: 'retainer_commission_percent_offline',
                    headerName: 'Commission Percent Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_percent_offline, 'float')
                  },
                  {
                    field: 'retainer_commission_offline',
                    headerName: 'Commission Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_offline, 'float')
                  },
                  {
                    field: 'retainer_order_amount_service',
                    headerName: 'Total Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_order_amount_service, 'float')
                  },
                  {
                    field: 'retainer_taxable_order_amount_service',
                    headerName: 'Total Taxable Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_taxable_order_amount_service, 'float')
                  },
                  {
                    field: 'retainer_commission_percent_service',
                    headerName: 'Commission Percent Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_percent_service, 'float')
                  },
                  {
                    field: 'retainer_commission_service',
                    headerName: 'Commission Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_service, 'float')
                  },
                  {
                    field: 'retainer_order_amount_online',
                    headerName: 'Total Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_order_amount_online, 'float')
                  },
                  {
                    field: 'retainer_taxable_order_amount_online',
                    headerName: 'Total Taxable Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_taxable_order_amount_online, 'float')
                  },
                  {
                    field: 'retainer_commission_percent_online',
                    headerName: 'Commission Percent Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.retainer_commission_percent_online, 'float')
                  },
                {
                  field: 'total_commission',
                  headerName: 'Total Commission',
                  valueGetter: params => {
                    const online = parseFloat(params.data.retainer_commission_online) || 0;
                    const offline = parseFloat(params.data.retainer_commission_offline) || 0;
                    const service = parseFloat(params.data.retainer_commission_service) || 0;
                    return (online + offline + service).toFixed(2);
                  }
                },
                {
                  field: 'tds',
                  headerName: 'TDS',
                  valueGetter: params => {
                    const total = parseFloat(params.getValue('total_commission')) || 0;
                    return (total * 0.10).toFixed(2);
                  }
                },
                {
                  field: 'receiveable_commission',
                  headerName: 'Receiveable Commission',
                  valueGetter: params => {
                    const total = parseFloat(params.getValue('total_commission')) || 0;
                    const tds = parseFloat(params.getValue('tds')) || 0;
                    return (total - tds).toFixed(2);
                  }
                },
                // { field: 'bank_name', headerName: 'Bank Name' },
                // { field: 'bank_account_no', headerName: 'Bank Account No' },
                // { field: 'ifsc_code', headerName: 'IFSC Code' },                
                {
                    headerName: 'Actions',
                    field: 'actions',
                    cellRenderer: params => (
                        <button 
                            className="btn btn-primary btn-sm" 
                            onClick={() => getOrderDetails(
                                params.data.order_item_ids,
                                params.data.retainer_commission_percent_online,
                                params.data.retainer_commission_percent_offline,
                                params.data.retainer_commission_percent_service,
                                'retainer'
                            )}
                        >
                            View Order Details
                        </button>
                    )
                }
            ];
        } else {
            return [...baseColumns,
                { 
                  field: 'total_user_shopping_amount_taxable',
                  headerName: 'User Total Taxable Amount',
                  sortable: true,
                  filter: true,
                  valueGetter: (params) => parseValue(params.data.total_user_shopping_amount_taxable, 'float')
                },
                { field: 'variant', headerName: 'Variant'},
                {
                    field: 'total_shopping_online_product',
                    headerName: 'Total Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_shopping_online_product, 'float')
                  },
                  {
                    field: 'total_taxable_shopping_online_product',
                    headerName: 'Total Taxable Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_shopping_online_product, 'float')
                  },
                  {
                    field: 'commission_percent_online_product',
                    headerName: 'Commission Percent Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_online_product, 'float')
                  },
                  {
                    field: 'total_shopping_offline_product',
                    headerName: 'Total Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_shopping_offline_product, 'float')
                  },
                  {
                    field: 'total_taxable_shopping_offline_product',
                    headerName: 'Total Taxable Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_shopping_offline_product, 'float')
                  },
                  {
                    field: 'commission_percent_offline_product',
                    headerName: 'Commission Percent Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_offline_product, 'float')
                  },
                  {
                    field: 'total_shopping_service',
                    headerName: 'Total Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_shopping_service, 'float')
                  },
                  {
                    field: 'total_taxable_shopping_service',
                    headerName: 'Total Taxable Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_shopping_service, 'float')
                  },
                  {
                    field: 'commission_percent_service',
                    headerName: 'Commission Percent Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_service, 'float')
                  },
                  {
                    field: 'ref_user_reg_id',
                    headerName: 'Referral User Id',
                    sortable: true,
                    filter: true
                  },
                  {
                    field: 'total_order_amount_shopping_online_product',
                    headerName: 'Total Order Amount Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_shopping_online_product, 'float')
                  },
                  {
                    field: 'total_taxable_amount_shopping_online_product',
                    headerName: 'Taxable Order Amount Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_shopping_online_product, 'float')
                  },
                  {
                    field: 'commission_shopping_online_product',
                    headerName: 'Commission Shopping Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_shopping_online_product, 'float')
                  },
                  {
                    field: 'total_order_amount_shopping_offline_product',
                    headerName: 'Total Order Amount Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_shopping_offline_product, 'float')
                  },
                  {
                    field: 'total_taxable_amount_shopping_offline_product',
                    headerName: 'Taxable Order Amount Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_shopping_offline_product, 'float')
                  },
                  {
                    field: 'commission_shopping_offline_product',
                    headerName: 'Commission Shopping Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_shopping_offline_product, 'float')
                  },
                  {
                    field: 'total_order_amount_shopping_service',
                    headerName: 'Total Order Amount Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_shopping_service, 'float')
                  },
                  {
                    field: 'total_taxable_amount_shopping_service',
                    headerName: 'Taxable Order Amount Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_shopping_service, 'float')
                  },
                  {
                    field: 'commission_shopping_service',
                    headerName: 'Commission Shopping Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_shopping_service, 'float')
                  },
                {
                  field: 'shopping_tds',
                  headerName: 'TDS Shopping',
                  valueGetter: params => {
                    const online = parseFloat(params.data.commission_shopping_online_product) || 0;
                    const offline = parseFloat(params.data.commission_shopping_offline_product) || 0;
                    const service = parseFloat(params.data.commission_shopping_service) || 0;
                    return ((online + offline + service) * 0.10).toFixed(2);
                  }
                },
                {
                  field: 'shopping_receiveable',
                  headerName: 'Receiveable Shopping',
                  valueGetter: params => {
                    const online = parseFloat(params.data.commission_shopping_online_product) || 0;
                    const offline = parseFloat(params.data.commission_shopping_offline_product) || 0;
                    const service = parseFloat(params.data.commission_shopping_service) || 0;
                    const total = online + offline + service;
                    return (total - (total * 0.10)).toFixed(2);
                  }
                },
                {
                    field: 'total_order_amount_retail_online_product',
                    headerName: 'Total Order Amount Retail Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_retail_online_product, 'float')
                  },
                  {
                    field: 'total_taxable_amount_retail_online_product',
                    headerName: 'Taxable Order Amount Retail Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_retail_online_product, 'float')
                  },
                  {
                    field: 'commission_percent_retail_online_product',
                    headerName: 'Commission Percent Retail Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_retail_online_product, 'float')
                  },
                  {
                    field: 'commission_retail_online_product',
                    headerName: 'Commission Retail Online Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_retail_online_product, 'float')
                  },
                  {
                    field: 'total_order_amount_retail_offline_product',
                    headerName: 'Total Order Amount Retail Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_retail_offline_product, 'float')
                  },
                  {
                    field: 'total_taxable_amount_retail_offline_product',
                    headerName: 'Taxable Order Amount Retail Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_retail_offline_product, 'float')
                  },
                  {
                    field: 'commission_percent_retail_offline_product',
                    headerName: 'Commission Percent Retail Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_retail_offline_product, 'float')
                  },
                  {
                    field: 'commission_retail_offline_product',
                    headerName: 'Commission Retail Offline Product',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_retail_offline_product, 'float')
                  },
                  {
                    field: 'total_order_amount_retail_service',
                    headerName: 'Total Order Amount Retail Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_order_amount_retail_service, 'float')
                  },
                  {
                    field: 'total_taxable_amount_retail_service',
                    headerName: 'Taxable Order Amount Retail Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.total_taxable_amount_retail_service, 'float')
                  },
                  {
                    field: 'commission_percent_retail_service',
                    headerName: 'Commission Percent Retail Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_percent_retail_service, 'float')
                  },
                  {
                    field: 'commission_retail_service',
                    headerName: 'Commission Retail Service',
                    sortable: true,
                    filter: true,
                    valueGetter: (params) => parseValue(params.data.commission_retail_service, 'float')
                  },                
                {
                  field: 'retail_tds',
                  headerName: 'TDS Retail',
                  valueGetter: params => {
                    const online = parseFloat(params.data.commission_retail_online_product) || 0;
                    const offline = parseFloat(params.data.commission_retail_offline_product) || 0;
                    const service = parseFloat(params.data.commission_retail_service) || 0;
                    return ((online + offline + service) * 0.10).toFixed(2);
                  }
                },
                {
                  field: 'retail_receiveable',
                  headerName: 'Receiveable Retail',
                  valueGetter: params => {
                    const online = parseFloat(params.data.commission_retail_online_product) || 0;
                    const offline = parseFloat(params.data.commission_retail_offline_product) || 0;
                    const service = parseFloat(params.data.commission_retail_service) || 0;
                    const total = online + offline + service;
                    return (total - (total * 0.10)).toFixed(2);
                  }
                },
                {
                  headerName: 'Actions',
                  field: 'actions',
                  cellRenderer: params => (
                      <div className="d-flex flex-column">
                          <div className="shopping-actions">
                              {params.data.order_item_ids_shopping && (
                                  <button 
                                      className="btn btn-primary btn-sm mb-2" 
                                      onClick={() => getOrderDetails(
                                          params.data.order_item_ids_shopping,
                                          params.data.commission_percent_online_product,
                                          params.data.commission_percent_offline_product,
                                          params.data.commission_percent_service,
                                          'shopping'
                                      )}
                                  >
                                      View Shopping Order Details
                                  </button>
                              )}
                          </div>
                          <div className="retail-actions">
                              {params.data.order_item_ids_retail && (
                                  <button 
                                      className="btn btn-primary btn-sm" 
                                      onClick={() => getOrderDetails(
                                          params.data.order_item_ids_retail,
                                          params.data.commission_percent_retail_online_product,
                                          params.data.commission_percent_retail_offline_product,
                                          params.data.commission_percent_retail_service,
                                          'retail'
                                      )}
                                  >
                                      View Retail Order Details
                                  </button>
                              )}
                          </div>
                      </div>
                  )
              }
            ];
        }
    };

    const orderDetailsColumnDefs = [
        {
            field: 's_no',
            headerName: 'S.No',
            valueGetter: 'node.rowIndex + 1'
        },
        {
            field: 'order_id',
            headerName: 'Order Id',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.order?.order_id || 'NA'
        },
        {
            field: 'order_date',
            headerName: 'Order Date',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.order?.created_at || 'NA'
        },
        {
            field: 'invoice_no',
            headerName: 'Invoice No',
            sortable: true,
            filter: true,
            valueGetter: params => {
                const invoices = params.data.order?.invoices;
                const invoiceType = ["shopping", "retainer"].includes(orderDetailsType) ? 'product' : 'seller_product';
                return invoices ? (invoices.find(invoice => invoice.invoice_type === invoiceType)?.invoice_no || "NA") : "NA";
            }
        },
        {
            field: 'product_name',
            headerName: 'Product/Service Name',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.product_item?.product.name ||
                                   params.data.offline_sale_product_datum?.product_description ||
                                   params.data.seller_service?.service_name || 'NA'
        },
        {
            field: 'online_sale_amount',
            headerName: 'Online Sale Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.product_id ? params.data.order_price : 0
        },
        {
            field: 'online_taxable_amount',
            headerName: 'Online Taxable Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.product_id ? params.data.taxable_amount : 0
        },
        {
            field: 'logistics_amount',
            headerName: 'Logistics Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.logistics_order?.order_price || 0
        },
        {
            field: 'logistics_taxable_amount',
            headerName: 'Logistics Taxable Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.logistics_order?.taxable_value || 0
        },
        {
            field: 'commission_percent_online',
            headerName: 'Commission Percent Online Sale',
            sortable: true,
            filter: true,
            valueGetter: params => (params.data.product_id || params.data.logistics_order) ? commissionPercentOnlineProduct : 0
        },
        {
            field: 'offline_sale_amount',
            headerName: 'Offline Sale Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.offline_sale_id && params.data.offline_sale_product_datum
                                   ? params.data.offline_sale_product_datum.sale_amount : 0
        },
        {
            field: 'offline_taxable_amount',
            headerName: 'Offline Taxable Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.offline_sale_id && params.data.offline_sale_product_datum
                                   ? params.data.offline_sale_product_datum.taxable_supply : 0
        },
        {
            field: 'commission_percent_offline',
            headerName: 'Commission Percent Offline Sale',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.offline_sale_id ? commissionPercentOfflineProduct : 0
        },
        {
            field: 'service_sale_amount',
            headerName: 'Service Sale Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.service_id ? params.data.order_price : 0
        },
        {
            field: 'service_taxable_amount',
            headerName: 'Service Taxable Amount',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.service_id ? Number(params.data.taxable_amount) : 0
        },
        {
            field: 'commission_percent_service',
            headerName: 'Commission Percent Service Sale',
            sortable: true,
            filter: true,
            valueGetter: params => params.data.service_id ? commissionPercentService : 0
        },
        {
            field: 'commission',
            headerName: 'Commission',
            sortable: true,
            filter: true,
            valueGetter: params => {
                const taxableAmount = parseFloat(params.data.taxable_amount) + parseFloat(params.data.logistics_order?.taxable_value || 0);
                const commissionPercent = params.data.product_id
                    ? commissionPercentOnlineProduct
                    : params.data.offline_sale_id
                    ? commissionPercentOfflineProduct
                    : commissionPercentService;
                return (taxableAmount * commissionPercent / 100).toFixed(2);
            }
        },
        {
            field: 'tds',
            headerName: 'TDS',
            sortable: true,
            filter: true,
            valueGetter: params => {
                const taxableAmount = parseFloat(params.data.taxable_amount) + parseFloat(params.data.logistics_order?.taxable_value || 0);
                const commissionPercent = params.data.product_id
                    ? commissionPercentOnlineProduct
                    : params.data.offline_sale_id
                    ? commissionPercentOfflineProduct
                    : commissionPercentService;
                const commission = taxableAmount * commissionPercent / 100;
                return (commission * 0.10).toFixed(2);
            }
        },
        {
            field: 'net_receivable',
            headerName: 'Net Receivable',
            sortable: true,
            filter: true,
            valueGetter: params => {
                const taxableAmount = parseFloat(params.data.taxable_amount) + parseFloat(params.data.logistics_order?.taxable_value || 0);
                const commissionPercent = params.data.product_id
                    ? commissionPercentOnlineProduct
                    : params.data.offline_sale_id
                    ? commissionPercentOfflineProduct
                    : commissionPercentService;
                const commission = taxableAmount * commissionPercent / 100;
                const tds = commission * 0.10;
                return (commission - tds).toFixed(2);
            }
        }
    ];
    

    const defaultColDef = {
        flex: 1,
        minWidth: 150,
        resizable: true
    };
  return (
    <>
          
            <h1>Buyer Commissions</h1>
            <br />
            <div className='row'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/><br/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <label>Commission Type</label>
                    <select id="commission_type" className='form-control'>
                        <option value="">Select Commission Type</option>
                        <option value="sale_retail">Sale/Retail Commission</option>
                        <option value="retainer">Retainer Commission</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <br/>
                    <button type="button" className='btn btn-primary btn-sm mt-2' id='submit_button' onClick={fetchUserCommissions}>Submit</button>
                </div>
                <div className='col-md-12'>
                <button className='btn button-product me-2' onClick={() => exportToCSV(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to CSV</button>
                <button className='btn button-product me-2' onClick={() => exportToExcel(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to Excel</button>
                <button className='btn button-product' onClick={() => exportToPDF(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to PDF</button>
                </div>
            </div>
            <br/>
            <div className="ag-theme-alpine" style={{height: '600px', width: '100%'}}>
            <AgGridReact
                columnDefs={getMainColumnDefs()}
                rowData={userCommissions}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                onSelectionChanged={addToPaymentList}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                headerCheckboxSelection={!$('#fetch_approved').is(':checked')}
                headerCheckboxSelectionFilteredOnly={true}
                rowHeight={rowHeight}
            />
            </div>
            <br/>
            {
                approveButton ? 
                    <center><button type='button' className='btn btn-success btn-sm' onClick={approvePayment}>Approve</button></center>
                : ""
            }
            
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <div className="mb-3">
                        <button className='btn btn-secondary me-2' onClick={handleExportCSVs}>Export to CSV</button>
                        <button className='btn btn-secondary me-2' onClick={handleExportExcels}>Export to Excel</button>
                        <button className='btn btn-secondary' onClick={handleExportPDFs}>Export to PDF</button>
                    </div>
                    
                    <div className="ag-theme-alpine" style={{height: '400px', width: '100%'}}>
                        <AgGridReact
                            columnDefs={orderDetailsColumnDefs}
                            rowData={orderDetails}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={10}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </>
  )
}

export default BuyerCommission;