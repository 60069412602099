import React, { useEffect,useState } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useCSVDownloader } from 'react-papaparse';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellerPayments = () => {
    let navigate = useNavigate();
    const { CSVDownloader } = useCSVDownloader();

    const [sellerPayments, setSellerPayments] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [approveButton, setApproveButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [vendorPayment, setVendorPayment] = useState(0);

    useEffect(()=>{
        checkAccess();
    },[])

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        }
    }

	const fetchSellerPayments = async () => {
        if ($('#payment_for').val() == "vendor") {
            setVendorPayment(1);
        } else {
            setVendorPayment(0);
        }
		const data = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            payment_for: $('#payment_for').val(),
            fetch_approved: $('#fetch_approved').is(':checked')
        }
		let response = await common.apiRequest('post', '/admin/fetch-seller-payments', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setSellerPayments(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const addToPaymentList = async (e, data) => {
        let list = paymentList;
        if (e.target.checked) {
            list.push(data);
        } else {
            list = list.filter(function( obj ) {
                return obj.seller_id !== data.seller_id;
            });
        }
        if (list.length > 0) {
            setApproveButton(true);
            $('#start_date').prop('disabled', true);
            $('#end_date').prop('disabled', true);
        } else {
            setApproveButton(false);
            $('#start_date').prop('disabled', false);
            $('#end_date').prop('disabled', false);
        }
        setPaymentList(list);
    }

    const approvePayment = async () => {
        let data = [];
        paymentList.map((payment) => {
            let tempData = {};
            tempData.seller_id = payment.seller_id;
            tempData.order_item_ids = vendorPayment ? payment.logistics_order_ids : payment.order_item_ids;
            tempData.order_payment_amount = payment.net_payment;
            tempData.payment_status = 'in_process';
            tempData.type = vendorPayment ? 'vendor' : 'supplier';
            data.push(tempData);
        });
        let finalData = {
            "action": "create",
            "data": data,
            "vendor_payment": vendorPayment
        }
        let response = await common.apiRequest('post', '/admin/crud-seller-payment', finalData);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                setApproveButton(false);
                $('#start_date').prop('disabled', false);
                $('#end_date').prop('disabled', false);
                fetchSellerPayments();
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const exportData = () => {
        const columns = [
            {
                id: 'reg_id',
                displayName: 'Reg Id'
            }, 
            {
                id: 'fist_name',
                displayName: 'First Name'
            }
        ];
    }

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
    }

    const getOrderDetails = async (orderIds) => {
        let data = {
            'ids': orderIds,
            'vendor_payment': vendorPayment
        }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }
    const data = sellerPayments.map((item, index) => ({
        "S.no": index + 1,
        "Reg Id": item.reg_id,
        "First Name": item.first_name,
        "Middle Name": item.middle_name,
        "Last Name": item.last_name,
        "Total Order Amount": item.total_order_amount,
        "Total Taxable Amount": item.total_taxable_amount,
        "Total TCS": item.total_tcs,
        "Net Payment": item.net_payment,
        "Bank Name": item.bank_name,
        "Bank Account No": item.bank_account_no,
        "IFSC Code": item.ifsc_code,
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'seller payment');
}

const handleExportExcel = () => {
    exportToExcel(data, 'seller payment');
}

const handleExportPDF = () => {
    exportToPDF(data, 'seller payment');
}
const orderData = orderDetails.map((order, index) => {
    return {
        "Order ID": order?.order?.order_id || 'NA',
        "Invoice No": order?.order?.invoices 
            ? (order.order.invoices.find(invoice => invoice.invoice_type === 'seller_product') || { invoice_no: "" }).invoice_no 
            : "",
        "Sale Type": order.offline_sale_id ? 'Offline Sale' : order.service_id ? 'Service Sale' : 'Online Sale',
        "Product/Service Name": order.product_item?.product?.name 
            || order.offline_sale_product_datum?.product_description 
            || order.seller_service?.service_name 
            || 'NA',
        "Order Price": vendorPayment ? order?.logistics_order?.order_price : order?.order_price,
        "Taxable Value": vendorPayment ? order?.logistics_order?.taxable_value : order?.taxable_amount,
        "GST Rate": vendorPayment ? order?.logistics_order?.gst_rate : order?.tax_percent,
        "Tax Amount": vendorPayment 
            ? (order?.logistics_order?.order_price - order?.logistics_order?.taxable_value)
            : (order?.order_price - order?.taxable_amount),
        "IGST Percent": vendorPayment 
            ? order?.logistics_order?.igst_percent 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.igst_rate : order?.igst_percent,
        "IGST Amount": vendorPayment 
            ? order?.logistics_order?.igst 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.igst_amount : order?.igst,
        "CGST Percent": vendorPayment 
            ? order?.logistics_order?.cgst_percent 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.cgst_rate : order?.cgst_percent,
        "CGST Amount": vendorPayment 
            ? order?.logistics_order?.cgst 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.cgst_amount : order?.cgst,
        "SGST Percent": vendorPayment 
            ? order?.logistics_order?.sgst_percent 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.sgst_utgst_rate : order?.sgst_percent,
        "SGST Amount": vendorPayment 
            ? order?.logistics_order?.sgst 
            : order.offline_sale_id ? order?.offline_sale_product_datum?.sgst_utgst_amount : order?.sgst,
        "UTGST Percent": vendorPayment ? order?.logistics_order?.utgst_percent : order?.utgst_percent,
        "UTGST Amount": vendorPayment ? order?.logistics_order?.utgst : order?.utgst,
        "Marketing Charges": vendorPayment ? null : order?.marketing_charges,
        "Discount": vendorPayment ? null : order?.discount,
        "Other Charges": vendorPayment ? null : order?.other_charges,
        "Shipment ID": order?.shipment_id,
        "Tracking No": order?.tracking_no,
        "TCS Rate": vendorPayment ? order?.logistics_order?.tcs_rate : order?.tcs_rate,
        "TCS Amount": vendorPayment ? order?.logistics_order?.tcs : order?.tcs,
        "Bank Charges": vendorPayment ? order?.logistics_order?.bank_charges : order?.bank_charges,
        "Commission Charges": vendorPayment ? order?.logistics_order?.commission_charges : order?.commission_charges,
        "Vendor Net Payment": vendorPayment ? order?.logistics_order?.vendor_net_payment : order?.supplier_net_payment
    };
});

const handleExportCSVs = () => {
    exportToCSV(orderData, 'seller payment');
}

const handleExportExcels = () => {
    exportToExcel(orderData, 'seller payment');
}

const handleExportPDFs = () => {
    exportToPDF(orderData, 'seller payment');
}

  return (
    <>
          
            <h1>Seller Payments</h1>
            <br />
            <div className='row'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/><br/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <label>Payment For</label>
                    <select className='form-control' id="payment_for">
                        <option value="supplier">Supplier</option>
                        <option value="vendor">Vendor</option>
                    </select>
                </div>
                <div>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
                <div className='col-md-3'>
                    <br/>
                    <button type="button" className='btn btn-primary btn-sm mt-2' onClick={fetchSellerPayments}>Submit</button>
                </div>
            </div>
            <br/>
            <table class="orders-table table-responsive report-margin commontop table-container">
                <thead>
                    <tr className='table-primary'>
                        <th></th>
                        <th>Reg Id</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th>Total Order Amount</th>
                        <th>Total Taxable Amount</th>
                        <th>Total TCS</th>
                        <th>Net Payment</th>
                        <th>Bank Name</th>
                        <th>Bank Account No</th>
                        <th>IFSC Code</th>
                        <th>View Order Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellerPayments.length > 0 ? 
                            sellerPayments.map((data) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{
                                            !$('#fetch_approved').is(':checked') ? 
                                            <input type='checkbox' onClick={(e) => addToPaymentList(e, data)}/>
                                            : null
                                            }
                                        </td>
                                        <td>{data.reg_id}</td>
                                        <td>{data.first_name}</td>
                                        <td>{data.middle_name}</td>
                                        <td>{data.last_name}</td>
                                        <td>{data.total_order_amount}</td>
                                        <td>{data.total_taxable_amount}</td>
                                        <td>{data.total_tcs}</td>
                                        <td>{data.net_payment}</td>
                                        <td>{data.bank_name}</td>
                                        <td>{data.bank_account_no}</td>
                                        <td>{data.ifsc_code}</td>
                                        <td><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids)}>View Details</button></td>
                                    </tr>
                                )
                            })
                        :
                        "No Data"
                    }
                </tbody>
            </table>
            <br/>
            {
                approveButton ? 
                    <center><button type='button' className='btn btn-success btn-sm' onClick={approvePayment}>Approve</button></center>
                : ""
            }
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <button className='btn button-product' onClick={handleExportCSVs}>Export to CSV</button>
                            <button className='btn button-product' onClick={handleExportExcels}>Export to Excel</button>
                            <button className='btn button-product' onClick={handleExportPDFs}>Export to PDF</button>
                <table class="orders-table table-responsive report-margin commontop table-container">
                    <thead>
                        <tr className='table-primary'>
                            <th>Order Id</th>
                            <th>Invoice No</th>
                            <th>Order Type</th>
                            <th>Product Name</th>
                            <th>Order Price</th>
                            <th>Taxable Amount</th>
                            <th>Tax Percent</th>
                            <th>TAX</th>
                            <th>IGST Percent</th>
                            <th>IGST</th>
                            <th>CGST Percent</th>
                            <th>CGST</th>
                            <th>SGST Percent</th>
                            <th>SGST</th>
                            <th>UTGST Percent</th>
                            <th>UTGST</th>
                            <th>Marketing Charges</th>
                            <th>Discount</th>
                            <th>Other Charges</th>
                            <th>Shipment ID</th>
                            <th>Tracking No</th>
                            <th>TCS Rate</th>
                            <th>TCS</th>
                            <th>Bank Charges</th>
                            <th>Commission Charges</th>
                            <th>Net Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderDetails.map((data) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{data.order.order_id}</td>
                                        <td>
                                            {data.order && data.order.invoices
                                            ? (data.order.invoices.find(invoice => vendorPayment ? invoice.invoice_type === "logistics" : invoice.invoice_type === 'seller_product') || { invoice_no: "" }).invoice_no : ""}
                                        </td>
                                        <td>{data.offline_sale_id ? 'Offline Sale' : data.service_id ? 'Service Sale' : 'Online Sale'}</td>
                                        <td>{data.product_item?.product.name || data.offline_sale_product_datum?.product_description || data.seller_service?.service_name || 'NA'}</td>
                                        <td>{vendorPayment ? data.logistics_order.order_price : data.order_price}</td>
                                        <td>{vendorPayment ? data.logistics_order.taxable_value : data.taxable_amount}</td>
                                        <td>{vendorPayment ? data.logistics_order.gst_rate : data.tax_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.order_price - data.logistics_order.taxable_value : data.order_price - data.taxable_amount}</td>
                                        <td>{vendorPayment ? data.logistics_order.igst_percent : data.offline_sale_id ? data.offline_sale_product_datum.igst_rate : data.igst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.igst : data.offline_sale_id ? data.offline_sale_product_datum.igst_amount : data.igst}</td>
                                        <td>{vendorPayment ? data.logistics_order.cgst_percent : data.offline_sale_id ? data.offline_sale_product_datum.cgst_rate : data.cgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.cgst : data.offline_sale_id ? data.offline_sale_product_datum.cgst_amount : data.cgst}</td>
                                        <td>{vendorPayment ? data.logistics_order.sgst_percent : data.offline_sale_id ? data.offline_sale_product_datum.sgst_utgst_rate : data.sgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.sgst : data.offline_sale_id ? data.offline_sale_product_datum.sgst_utgst_amount : data.sgst}</td>
                                        <td>{vendorPayment ? data.logistics_order.utgst_percent : data.utgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.utgst : data.utgst}</td>
                                        <td>{vendorPayment ? null : data.marketing_charges}</td>
                                        <td>{vendorPayment ? null : data.discount}</td>
                                        <td>{vendorPayment ? null : data.other_charges}</td>
                                        <td>{data.shipment_id}</td>
                                        <td>{data.tracking_no}</td>
                                        <td>{vendorPayment ? data.logistics_order.tcs_rate : data.tcs_rate}</td>
                                        <td>{vendorPayment ? data.logistics_order.tcs : data.tcs}</td>
                                        <td>{vendorPayment ? data.logistics_order.bank_charges : data.bank_charges}</td>
                                        <td>{vendorPayment ? data.logistics_order.commission_charges : data.commission_charges}</td>
                                        <td>{vendorPayment ? data.logistics_order.vendor_net_payment : data.supplier_net_payment}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </ModalBody>
            </Modal>
        </>
  )
}

export default SellerPayments;