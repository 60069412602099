import React, { useState, useEffect } from "react";
import { Button} from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./banner.css";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Test from './Test';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Banner = () => {
  let navigate = useNavigate();
	const [logos, setlogos] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerUrl, setBannerUrl] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [cmsData, setCmsData] = useState({
    'about_us' : '',
    'contact_us' : '',
    'payments' : '',
    'shipping' : '',
    'cancellation_and_returns' : '',
    'faq' : '',
    'terms_of_use' : '',
    'security' : '',
    'privacy' : '',
    'legal_disclamer' : '',
    'terms_and_condition_seller' : '',
    'terms_and_condition_customer' : ''
  });
 

  useEffect(() => {
    checkAccess();
  },[]);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      if (!localStorage.getItem('token')) {
        navigate('/login');
      }
      getCmsData();
    }
  }

  const getCmsData = async () => {
    var data = {
      'id' : 1
    };
    let response = await common.apiRequest('post', '/admin/get-cms-data', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
        if (response.data.cmsData.length > 0) {
          setCmsData(response.data.cmsData[0]);
          if (response.data.logoBannerData && response.data.logoBannerData.length > 0) {
            setLogoUrl(response.data.logoBannerData[0]['logo']);
            setBannerUrl(response.data.logoBannerData[0]['banners'].split(','));
          }
          delete cmsData.created_at;
          delete cmsData.updated_at;
          delete cmsData.last_updated_by;
          delete cmsData.logo;
          delete cmsData.banners;
        }
			} else {
				$('#error').html(response.data.error);
			}
		} else {
			$('#error').html(response);
		}
  }

  function onImageChange(e, type) {
    if (type == "logo") {
      setlogos(e.target.files);
    } else if (type == "banners") {
      setBanners(e.target.files);
    }
  }

 

  const handleChange = (e,editor,id)=>{
    cmsData[id] = editor.getData();
  }

  const saveCMSData = async () => {
    cmsData.last_updated_by = localStorage.getItem('userId');
    let response = await common.apiRequest('post', '/admin/save-cms-data', cmsData);
    if (response.status == 200) {
      if (response.data.code == 'success') {
        if (!cmsData.id) {
          getCmsData();
        }
        alert('update');
        navigate('/cms');
      } else {
        alert('Something went wrong');
        navigate('/cms');
      }
    } else {
      alert('Something went wrong');
      navigate('/');
    }
  }

  const uploadLogo = async () => {
    var fileData = new FormData();
    fileData.append('logo', logos[0]);
    var response = await common.apiRequest('post', '/admin/update-logo', fileData);
    resetLogoAndBannerValues(response);
  }

  const uploadBanner = async () => {
    var fileData = new FormData();
    for (const key of Object.keys(banners)) {
      fileData.append('banners', banners[key]);
    }
    var response = await common.apiRequest('post', '/admin/update-banners', fileData);
    resetLogoAndBannerValues(response);
  }

  const resetLogoAndBannerValues = (response) => {
    if (response.data.logoBannerData && response.data.logoBannerData.length > 0) {
      setLogoUrl(response.data.logoBannerData[0]['logo']);
      setBannerUrl(response.data.logoBannerData[0]['banners'].split(','));
    }
    setlogos([]);
    setBanners([]);
    $('#logo').val('');
    $('#banner').val('');
  }

  const updateLogoBanner = (type) => {
    if (type == "logo") {
      uploadLogo();
    } else if (type == "banner") {
      uploadBanner();
    }
  }
  const [receivedPhotoLink, setReceivedPhotoLink] = useState('');
  const handlePhotoLinkReceived = (photoLink) => {
    setReceivedPhotoLink(photoLink);
  };


  return ( 
	<>
  <div className="" style={{ backgroundColor: '#D3D3D3'}}>
  <div className="container" >
  <section className="section">
      <h1 className="headder1">logo Upload</h1>
      <div className="photos">
        <img src={envConfig.api_url + logoUrl} alt="Logo" width={"250px"} />
      </div>
      <div class="banner">
        <div class="center">
          <input type="file" id="logo" accept="image/*" onChange={(e) => {onImageChange(e, 'logo')}} />
            {
              logos.length > 0 ? <Button type='button' className='submit-button' onClick={() => {updateLogoBanner('logo')}}>Submit</Button> : "Select Image"
            }
        </div>
      </div>
    </section>
    <section className="section">
      <h1 className="headder1">Banner Upload</h1>
        <div className="photos">
              {bannerUrl.map((imageSrc) => (
                <img src={envConfig.api_url + imageSrc} alt="Banners" width={"250px"} />
              ))}
        </div>
        <div class="banner">
          <div class="center">
            <input type="file" id="bannner" multiple accept="image/*" onChange={(e) => {onImageChange(e, 'banners')}} />
            {
                banners.length > 0 ? <Button type='button' className='submit-button' onClick={() => {updateLogoBanner('banner')}}>Submit</Button> : "Select Image"
              }
          </div>
        </div>
    </section>
    <section className="section">
      <h1 className="headder1">T&C Form</h1>
      <div className="textarea-container">
        <label htmlFor="custom-textarea" className="textarea-label">About us:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'about_us')}} data={cmsData && cmsData.about_us ? cmsData.about_us : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Contact us:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'contact_us')}} data={cmsData.contact_us ? cmsData.contact_us : ''}/>
      </div>
      <div className="textarea-container">
        <label htmlFor="custom-textarea" className="textarea-label">Payments:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'payments')}} data={cmsData.payments ? cmsData.payments : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Shipping:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'shipping')}} data={cmsData.shipping ? cmsData.shipping : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Cancellation & Return:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'cancellation_and_returns')}} data={cmsData.cancellation_and_returns ? cmsData.cancellation_and_returns : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">FAQ:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'faq')}} data={cmsData.faq ? cmsData.faq : ''}/>
      </div>
      <div className="textarea-container">
        <label htmlFor="custom-textarea" className="textarea-label">Terms of use:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'terms_of_use')}} data={cmsData.terms_of_use ? cmsData.terms_of_use : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Security:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'security')}} data={cmsData.security ? cmsData.security : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Privacy:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'privacy')}} data={cmsData.privacy ? cmsData.privacy : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Legal Disclaimer:</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'legal_disclamer')}} data={cmsData.legal_disclamer ? cmsData.legal_disclamer : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Terms & Condition (Customer):</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'terms_and_condition_customer')}} data={cmsData.terms_and_condition_customer ? cmsData.terms_and_condition_customer : ''}/>
        <label htmlFor="custom-textarea" className="textarea-label">Terms & Condition (Seller):</label>
        <CKEditor editor={ClassicEditor} onChange={(e,editor)=>{handleChange(e,editor,'terms_and_condition_seller')}} data={cmsData.terms_and_condition_seller ? cmsData.terms_and_condition_seller : ''}/>
      </div>
    </section>
    <center><button className="btn btn-primary" onClick={saveCMSData}>Save</button></center>
  </div>
  </div>
 
   
  </>
  )
}

export default Banner
