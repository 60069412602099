import React, { useEffect, useState } from 'react';
import { Table, Button } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

const common = require('../utils/Common');

const BuyerOrders = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [allOrders, setAllOrders] = useState([]);
    const [orderStatuses, setOrderStatuses] =useState([]);
console.log(location.pathname,"changes")
	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            getAllOrders();
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        }
    }

    const getAllOrders = async () => {
        let data = {
            'user_id' : location.state
        }
        let response = await common.apiRequest('post', '/orders/get-orders', data);
        if (response && response.data && response.data.code == 'success') {
            setAllOrders(response.data.data);
        } else {
            setAllOrders([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId) => {
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status' : orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == 'success') {
            alert(response.data.message.replaceAll("_", " "));
            getAllOrders();
        } else {
            alert(response.data.error, 'error');
        }
    }
    const orderData = allOrders.flatMap((order, orderIndex) =>
    order.order_items.map((orderItem, itemIndex) => ({
        "S. No.": orderIndex + 1,
        "Order ID": order.order_id,
        "Sale Type": orderItem.product_item
            ? "Online Product Sale"
            : orderItem.seller_service
            ? "Online Service Sale"
            : "Offline Product Sale",
        "Name/Description": orderItem.product_item
            ? orderItem.product_item.product.name
            : orderItem.seller_service
            ? orderItem.seller_service.service_name
            : orderItem.offline_sale_product_datum
            ? orderItem.offline_sale_product_datum.product_description
            : "NA",
        "Description": orderItem.product_item
            ? orderItem.product_item.product.description
            : orderItem.seller_service
            ? orderItem.seller_service.service_description
            : orderItem.offline_sale_product_datum
            ? orderItem.offline_sale_product_datum.product_description
            : "NA",
        "Order Price": orderItem.order_price,
        "Order Quantity": orderItem.order_quantity,
        "Order Status": orderItem.order_status,
        "Order Completed On": orderItem.order_completed_on,
        "Order Cancelled On": orderItem.order_cancelled_on,
        "Payment ID": orderItem.payment_id,
        "Payment Status": orderItem.payment_status
    }))
);

    const handleExportCSVs = () => {
        exportToCSV(orderData, 'buyerorders');
    }
    
    const handleExportExcels = () => {
        exportToExcel(orderData, 'buyerorders');
    }
    
    const handleExportPDFs = () => {
        exportToPDF(orderData, 'buyerorders');
    }
    return (
        <>
        <h1>All Orders</h1><br/>
        <button className='btn button-product' onClick={handleExportCSVs}>Export to CSV</button>
        <button className='btn button-product' onClick={handleExportExcels}>Export to Excel</button>
        <button className='btn button-product' onClick={handleExportPDFs}>Export to PDF</button>

            <table>
                <thead>
                    <tr className='table-primary'>
                        <th>Order ID</th>
                        <th>Order Type</th>
                        <th>Product Name</th>
                        <th>Product Description</th>
                        <th>Order Price</th>
                        <th>Order Quantity</th>
                        <th>Order Status</th>
                        <th>Order Completed On</th>
                        <th>Order Cancelled On</th>
                        <th>Payment ID</th>
                        <th>Payment Status</th>
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {
                        allOrders.length == 0 ?
                        <tr>
                            <td align="center" colspan="12">
                                <center>No Orders Available</center>
                            </td>
                        </tr>
                        :
                        allOrders.map((order)=> {
                            let orderId = order.order_id;
                            return (
                                order.order_items.map((orderItem) => {
                                    return (
                                        <tr>
                                            <td>{orderId}</td>
                                            <td>{orderItem.product_item ? "Online Product Sale" : orderItem.seller_service ? "Online Service Sale" : "Offline Product Sale"}</td>
                                            <td>{orderItem.product_item ? orderItem.product_item.product.name : orderItem.seller_service ? orderItem.seller_service.service_name : orderItem.offline_sale_product_datum ? orderItem.offline_sale_product_datum.product_description : "NA"}</td>
                                            <td>{orderItem.product_item ? orderItem.product_item.product.description : orderItem.selelr_service ? orderItem.seller_service.service_description : orderItem.offline_sale_product_datum ? orderItem.offline_sale_product_datum.product_description : "NA"}</td>
                                            <td>{orderItem.order_price}</td>
                                            <td>{orderItem.order_quantity}</td>
                                            <td>
                                                <>
                                                    <select id="order_status" value={orderItem.order_status} className="form-control" onChange={(e) => changeOrderStatus(orderItem.id, e.target.value, orderItem.order_price, orderItem.order.user_id)} style={{width: '130px'}}>
                                                        {
                                                            orderStatuses && orderStatuses.product && (orderItem.product_item || orderItem.offline_sale_product_datum) ? Object.entries(orderStatuses.product).map(([key, value]) => (
                                                                <option key={key} value={key}>{value}</option>
                                                            ))
                                                            :
                                                            orderStatuses && orderStatuses.service && orderItem.seller_service ? Object.entries(orderStatuses.service).map(([key, value]) => (
                                                                <option key={key} value={key}>{value}</option>
                                                            )) : null
                                                        }
                                                    </select>
                                                </>
                                            </td>
                                            <td>{orderItem.order_completed_on}</td>
                                            <td>{orderItem.order_cancelled_on}</td>
                                            <td>{orderItem.payment_id}</td>
                                            <td>{orderItem.payment_status}</td>
                                        </tr>
                                    );
                                })
                            );
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default BuyerOrders;