import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SystemConfig = () => {
    let navigate = useNavigate();
    const [allConfig, setAllConfig] = useState([]);
    const [editConfig, setEditConfig] = useState({key: "", value: "", id: ""});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudSystemConfig(0, 'read');
        }
    }

    const addNewConfig = () => {
        toggle();
    }

    const crudSystemConfig = async (id, action) => {
        let data = {
            'id': id,
            'action': action
        }
        if (action == 'create' || action == 'update') {
            data.key = $('#key').val().replace(/(\r\n|\n|\r)/gm, "");
            data.value = $('#value').val().replaceAll(/(\r\n|\n|\r)/gm, "");
        }
        let response = await common.apiRequest('post', '/admin/crud-system-config', data);
        if (response.data.code == 'success') {
            if (action == "read") {
                setAllConfig(response.data.data);
            } else {
                alert(response.data.message);
                if (action != "delete") {
                    toggle();
                }
                crudSystemConfig(0, 'read');
            }
        } else {
            alert(response.data.error);
        }
    }

    const editSystemConfig = (config) => {
        setEditConfig({
            key: config.key,
            value: JSON.stringify(config.value, null, 2),
            id: config._id
        });
        toggle();
    }

    const [columnDefs] = useState([
        { 
            field: 'key',
            headerName: 'Key',
            sortable: true,
            filter: 'agTextColumnFilter'
        },
        { 
            field: 'value',
            headerName: 'Value',
            sortable: true,
            filter: 'agTextColumnFilter',
            cellRenderer: params => JSON.stringify(params.value)
        },
        {
            headerName: 'Action',
            width: 200,
            cellRenderer: params => (
                <div>
                    <button className='btn btn-warning btn-sm me-2' 
                            onClick={() => editSystemConfig(params.data)}>
                        Edit
                    </button>
                    <button className='btn btn-danger btn-sm'
                            onClick={() => crudSystemConfig(params.data._id, 'delete')}>
                        Delete
                    </button>
                </div>
            )
        }
    ]);
    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        floatingFilter: true,
    }), []);

    return (
        <>
        <div className='col-md-12'>
            <div className='row'>
                <div className='col-md-6'>
                    <h1>All Configs</h1>
                </div>
                <div className='col-md-6'>
                <button className='btn btn-primary btn-sm float-right mr-3 mb-5' onClick={addNewConfig}>Add New Config</button>
                </div>
            </div>
        </div>
                                <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                                    <AgGridReact
                                        rowData={allConfig}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationPageSizeSelector={[10, 25, 50]}
                                    />
                                </div>
        <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Add Config</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Key</label>
                    </div>
                    <div className='col-md-10'>
                        <input type='text' className='form-control' id="key" defaultValue={editConfig.key}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'>
                        <label>Value</label>
                    </div>
                    <div className='col-md-10'>
                        <textarea id="value" className='form-control' defaultValue={editConfig.value} rows="20"></textarea>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'></div>
                    <div className='col-md-10'>
                        <button className='btn btn-primary btn-sm' onClick={() => crudSystemConfig(editConfig.id ? editConfig.id: 0, editConfig.id ? 'update' : 'create')}>Submit</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        </>
    )
}

export default SystemConfig;