import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellerserviceApprove = () => {
    const location = useLocation();

    let navigate = useNavigate();
    const [sellersServiceData, setSellersServiceData] = useState([]);
    const [normalServiceModal, setNormalServiceModal] = useState(false);
    const [logisticsServiceModal, setLogisticsServiceModal] = useState(false);
    const [selectedServiceData, setSelectedServiceData] = useState(null);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);
    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            getServices();
        }
    };

    const getServices = async () => {
        const data = {
            action: "read",
            source: "admin",
            user_type: "seller",
            'seller_id': location.state.seller_id,
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/admin/get-sellers-service-data', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                setSellersServiceData(response.data.data);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    };
    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }

    const viewPincodes = async (pincodes) => {
        if (pincodes) {
            pincodes = pincodes.split('--');
            pincodes[0] = pincodes[0].replaceAll('-', '');
            pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
            let data = {
                'pincodes': pincodes,
            }
            let response = await common.apiRequest('post', '/seller/get-pincodes', data);
            if (response.data.code === 'success') {
                togglePincodeModal();
                setPincodes(response.data.data);
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    }
    const approveProduct = async (product_id) => {
        let data = {
            'id': product_id,
            "active": 1,
            "action": "update"
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            setSellersServiceData(prevData => 
                prevData.map(seller => ({
                    ...seller,
                    seller_services: seller.seller_services.map(service => 
                        service.id === product_id ? { ...service, active: 1 } : service
                    )
                }))
            );
            setSelectedServiceData(prevData => 
                prevData.map(service => 
                    service.id === product_id ? { ...service, active: 1 } : service
                )
            );
            alert(response.data.message);
        } else {
            alert(response.data.error);
        }
    };
    const movePage = (path, data) => {
        navigate(path, { state: data });
    };

    const toggleNormalServiceModal = (serviceData) => {
        setSelectedServiceData(serviceData);
        setNormalServiceModal(!normalServiceModal);
    };

    const toggleLogisticsServiceModal = (serviceData) => {
        setSelectedServiceData(serviceData);
        setLogisticsServiceModal(!logisticsServiceModal);
    };

    const serviceColumnDefs = useMemo(() => [
        { headerName: "Service Name", field: "service_name" },
        { headerName: "Parent Category", field: "parent_category.category_name" },
        { headerName: "Sub Category", field: "sub_category.category_name" },
        { headerName: "Service Date", field: "created_at" },
        { headerName: "Service Type", field: "service_type" },
        {
            headerName: "Service Image", field: "service_image",
            cellRenderer: (params) => (
                params.value ? <img src={envConfig.api_url + params.value} style={{ height: '100px', width: '100px' }} alt="Service" /> : ''
            )
        },
        { headerName: "Service Description", field: "service_description" },
        { headerName: "Service Price", field: "price" },
        { headerName: "Service SKU", field: "service_sku" },
        { headerName: "Service By", field: "service_by" },
        { headerName: "GST Rate", field: "gst_rate" },
        { headerName: "Taxable Value", field: "taxable_value" },
        { headerName: "Supply From State", field: "supply_from_state" },
        { headerName: "Supply From GST No", field: "supply_from_gst_no" },
        { headerName: "Logistics Delivery Type", field: "logistics_delivery_type" },
        { headerName: "Range In Grams From", field: "range_in_grams_from" },
        { headerName: "Range In Grams To", field: "range_in_grams_to" },
        { headerName: "Zone A", field: "zone_a" },
        { headerName: "Zone B", field: "zone_b" },
        { headerName: "Zone C", field: "zone_c" },
        { headerName: "Zone D", field: "zone_d" },
        { headerName: "Zone E", field: "zone_e" },
        { headerName: "Zone F", field: "zone_f" },
        { headerName: "Minimum Delivery Time", field: "min_delivery_time" },
        { headerName: "Maximum Delivery Time", field: "max_delivery_time" },
        { headerName: "Additional Grams", field: "additional_grams" },
        { headerName: "Additional Price Add In Every Zone", field: "additional_price_add_in_every_zone" },
        {
            headerName: "Pincodes",
            cellRenderer: (params) => (
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.seller_service_pincodes.length > 0 ? params.data.seller_service_pincodes[0].pincode : null)}>View Pincodes</button>
            )
        },
        {
            headerName: "Actions",
            cellRenderer: (params) => {
                return (
                    params.data.active == 1 ? "Approved" : (
                        <Button color="success" size="sm" onClick={() => approveProduct(params.data.id)}>
                            Approve Service
                        </Button>
                    )
                );
            }
        }
        
    ], []);
    const sellerColumnDefs = useMemo(() => {
        const columnDefs = [
            { headerName: "Reg ID", field: "reg_id" },
            { headerName: "PAN No", field: "pan_no" },
            { headerName: "First Name", field: "first_name" },
            { headerName: "Middle Name", field: "middle_name" },
            { headerName: "Last Name", field: "last_name" },
            { headerName: "Email", field: "email" },
            { headerName: "Mobile Phone", field: "mobile_phone" },
        ];
    
        if (location.state.rowType !== 'logistics') {
            columnDefs.push({
                headerName: "Normal service", field: "normal_service",
                cellRenderer: (params) => (
                    <Button color="primary" size="sm" onClick={() => toggleNormalServiceModal(params.data.seller_services.filter(service => service.service_type === 'normal'))}>
                        Normal service
                    </Button>
                )
            });
        }
    
        if (location.state.rowType !== 'service') {
            columnDefs.push({
                headerName: "Logistics service", field: "logistics_service",
                cellRenderer: (params) => (
                    <Button color="primary" size="sm" onClick={() => toggleLogisticsServiceModal(params.data.seller_services.filter(service => service.service_type === 'logistics'))}>
                        Logistics service
                    </Button>
                )
            });
        }
    
        return columnDefs;
    }, [location.state.rowType]);
    

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
    }), []);
    const modalStyle = {
        maxWidth: '90vw',
        width: '90vw',
        maxHeight: '90vh',
        height: '90vh',
    };

    const modalBodyStyle = {
        height: 'calc(90vh - 56px)',
        padding: '1rem',
        overflow: 'hidden', 
    };

    const gridContainerStyle = {
        height: '100%',
        width: '100%',
        overflow: 'auto', 
    };

    const gridStyle = {
        height: '100%',
        minWidth: '100%',
    };
    const data = sellersServiceData.map((product) => {
        return product.seller_services.map((service) => ({
            "Reg ID": product.reg_id,
            "PAN No": product.pan_no,
            "Service Date": service.created_at,
            "First Name": product.first_name,
            "Middle Name": product.middle_name,
            "Last Name": product.last_name,
            "Email": product.email,
            "Mobile Phone": product.mobile_phone,
            "Service Name": service.service_name,
            "Parent Category": service.parent_category?.category_name || null,
            "Sub Category": service.sub_category?.category_name || null,
            "Service Type": service.service_type || null,
            "Service Description": service.service_description || null,
            "Service Price": service.price || null,
            "Service SKU": service.service_sku || null,
            "Service By": service.service_by || null,
            "GST Rate": service.gst_rate || null,
            "Taxable Value": service.taxable_value || null,
            "Supply From State": service.supply_from_state || null,
            "Supply From GST No": service.supply_from_gst_no || null,
            "Logistics Delivery Type": service.logistics_delivery_type || null,
            "Range In Grams From": service.range_in_grams_from || null,
            "Range In Grams To": service.range_in_grams_to || null,
            "Zone A": service.zone_a || null,
            "Zone B": service.zone_b || null,
            "Zone C": service.zone_c || null,
            "Zone D": service.zone_d || null,
            "Zone E": service.zone_e || null,
            "Zone F": service.zone_f || null,
            "Minimum Delivery Time": service.min_delivery_time || null,
            "Maximum Delivery Time": service.max_delivery_time || null,
            "Additional Grams": service.additional_grams || null,
            "Additional Price Add In Every Zone": service.additional_price_add_in_every_zone || null,
        }));
    }).flat(); 
    
    
    
    const handleExportCSV = () => {
        exportToCSV(data, 'service');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'service');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'service');
    }
        
    return (
        <>
            <h1>All Services</h1>
            <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getServices} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                        <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getServices} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                    </FormGroup>
                </Form>
            <div>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            <br />
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    rowData={sellersServiceData}
                    columnDefs={sellerColumnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>

            <Modal isOpen={normalServiceModal} toggle={() => toggleNormalServiceModal(null)} size="xl" style={modalStyle}>
                <ModalHeader toggle={() => toggleNormalServiceModal(null)}>Normal Service</ModalHeader>
                <ModalBody style={modalBodyStyle}>
                    <div style={gridContainerStyle}>
                        <div className="ag-theme-quartz" style={gridStyle}>
                            <AgGridReact
                                rowData={selectedServiceData}
                                columnDefs={serviceColumnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <div style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                        {
                            pincodes.map((pincode) => {
                                return (
                                    <div key={pincode.pincode} style={{ marginBottom: '10px' }}>
                                        {pincode.pincode}
                                    </div>
                                );
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={logisticsServiceModal} toggle={() => toggleLogisticsServiceModal(null)} size="xl" style={modalStyle}>
                <ModalHeader toggle={() => toggleLogisticsServiceModal(null)}>Logistics Service</ModalHeader>
                <ModalBody style={modalBodyStyle}>
                    <div style={gridContainerStyle}>
                        <div className="ag-theme-quartz" style={gridStyle}>
                            <AgGridReact
                                rowData={selectedServiceData}
                                columnDefs={serviceColumnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default SellerserviceApprove;
