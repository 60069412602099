import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
const common = require('../utils/Common');

const BuyerOrders = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [allOrders, setAllOrders] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            getAllOrders();
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        }
    }

    const getAllOrders = async () => {
        let data = {
            'user_id': location.state
        }
        let response = await common.apiRequest('post', '/orders/get-orders', data);
        if (response && response.data && response.data.code === 'success') {
            setAllOrders(response.data.data);
            const formattedData = response.data.data.flatMap((order) =>
                order.order_items.map((orderItem) => ({
                    orderId: order.order_id,
                    saleType: orderItem.product_item ? "Online Product Sale" : orderItem.seller_service ? "Online Service Sale" : "Offline Product Sale",
                    name: orderItem.product_item ? orderItem.product_item.product.name : orderItem.seller_service ? orderItem.seller_service.service_name : "NA",
                    description: orderItem.product_item ? orderItem.product_item.product.description : orderItem.seller_service ? orderItem.seller_service.service_description : "NA",
                    orderPrice: orderItem.order_price,
                    orderQuantity: orderItem.order_quantity,
                    orderStatus: orderItem.order_status,
                    orderCompletedOn: orderItem.order_completed_on,
                    orderCancelledOn: orderItem.order_cancelled_on,
                    paymentId: orderItem.payment_id,
                    paymentStatus: orderItem.payment_status,
                    sellerPaymentStatus: orderItem.seller_payment_status,
                    bankCharges: orderItem.bank_charges,
                    cartageCharges: orderItem.cartage_charges,
                    marketingCharges: orderItem.marketing_charges,
                    commissionCharges: orderItem.commission_charges,
                    discount: orderItem.discount,
                    otherCharges: orderItem.other_charges,
                    tds: orderItem.tds,
                    buyerPaymentStatus: orderItem.buyer_payment_status,
                    offlineSaleId: orderItem.offline_sale_id,
                    vendorPaymentStatus: orderItem.vendor_payment_status,
                    taxableAmount: orderItem.taxable_amount,
                    taxPercent: orderItem.tax_percent,
                    cgstPercent: orderItem.cgst_percent,
                    cgst: orderItem.cgst,
                    sgstPercent: orderItem.sgst_percent,
                    sgst: orderItem.sgst,
                    igstPercent: orderItem.igst_percent,
                    igst: orderItem.igst,
                    orderShippedOn: orderItem.order_shipped_on,
                    orderProcessedOn: orderItem.order_processed_on,
                    walletAmount: orderItem.wallet_amount,
                    utgstPercent: orderItem.utgst_percent,
                    utgst: orderItem.utgst,
                    trackingNo: orderItem.tracking_no,
                    orderPickedOn: orderItem.order_picked_on,
                    rnoteNo: orderItem.rnote_no,
                    rnoteType: orderItem.rnote_type,
                    rnoteSupplyType: orderItem.rnote_supply_type,
                    rnoteDate: orderItem.rnote_date,
                    rnoteValue: orderItem.rnote_value,
                    rgstRate: orderItem.rgst_rate,
                    rtaxableValue: orderItem.rtaxable_value,
                    rigstPercent: orderItem.rigst_percent,
                    rigst: orderItem.rigst,
                    rcgstPercent: orderItem.rcgst_percent,
                    rcgst: orderItem.rcgst,
                    rsgstPercent: orderItem.rsgst_percent,
                    rsgst: orderItem.rsgst,
                    rutgstPercent: orderItem.rutgst_percent,
                    rutgst: orderItem.rutgst,
                    rirn: orderItem.rirn,
                    rirnDate: orderItem.rirn_date,
                    tcs: orderItem.tcs,
                    tcsRate: orderItem.tcs_rate,
                    tigst: orderItem.tigst,
                    tcgst: orderItem.tcgst,
                    tsgst: orderItem.tsgst,
                    tutgst: orderItem.tutgst,
                    taxableBankCharges: orderItem.taxable_bank_charges,
                    bigst: orderItem.bigst,
                    bcgst: orderItem.bcgst,
                    bsgst: orderItem.bsgst,
                    butgst: orderItem.butgst
                }))
            );
            setRowData(formattedData);
        } else {
            setAllOrders([]);
            setRowData([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId) => {
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            alert(response.data.message.replaceAll("_", " "));
            getAllOrders();
        } else {
            alert(response.data.error, 'error');
        }
    }

    const columnDefs = [
        { field: 'orderId', headerName: 'Order ID', filter: true },
        { field: 'saleType', headerName: 'Sale Type', filter: true },
        { field: 'name', headerName: 'Name/Description', filter: true },
        { field: 'description', headerName: 'Description', filter: true },
        { field: 'orderPrice', headerName: 'Order Price', filter: 'agNumberColumnFilter' },
        { field: 'orderQuantity', headerName: 'Order Quantity', filter: 'agNumberColumnFilter' },
        { field: 'orderStatus', headerName: 'Order Status', filter: true },
        { field: 'orderCompletedOn', headerName: 'Order Completed On', filter: true },
        { field: 'orderCancelledOn', headerName: 'Order Cancelled On', filter: true },
        // { field: 'paymentId', headerName: 'Payment ID', filter: true },
        // { field: 'paymentStatus', headerName: 'Payment Status', filter: true },
        // { field: 'bankCharges', headerName: 'Bank Charges', filter: 'agNumberColumnFilter' },
        // { field: 'cartageCharges', headerName: 'Cartage Charges', filter: 'agNumberColumnFilter' },
        // { field: 'marketingCharges', headerName: 'Marketing Charges', filter: 'agNumberColumnFilter' },
        // { field: 'commissionCharges', headerName: 'Commission Charges', filter: 'agNumberColumnFilter' },
        // { field: 'discount', headerName: 'Discount', filter: 'agNumberColumnFilter' },
        // { field: 'otherCharges', headerName: 'Other Charges', filter: 'agNumberColumnFilter' },
        // { field: 'tds', headerName: 'TDS', filter: 'agNumberColumnFilter' },
        // { field: 'buyerPaymentStatus', headerName: 'Buyer Payment Status', filter: true },
        // { field: 'offlineSaleId', headerName: 'Offline Sale ID', filter: true },
        // { field: 'vendorPaymentStatus', headerName: 'Vendor Payment Status', filter: true },
        { field: 'taxableAmount', headerName: 'Taxable Amount', filter: 'agNumberColumnFilter' },
        { field: 'taxPercent', headerName: 'Tax Percent', filter: 'agNumberColumnFilter' },
        { field: 'cgstPercent', headerName: 'CGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'cgst', headerName: 'CGST', filter: 'agNumberColumnFilter' },
        { field: 'sgstPercent', headerName: 'SGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'sgst', headerName: 'SGST', filter: 'agNumberColumnFilter' },
        { field: 'igstPercent', headerName: 'IGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'igst', headerName: 'IGST', filter: 'agNumberColumnFilter' },
        // { field: 'orderShippedOn', headerName: 'Order Shipped On', filter: true },
        // { field: 'orderProcessedOn', headerName: 'Order Processed On', filter: true },
        { field: 'walletAmount', headerName: 'Wallet Amount', filter: 'agNumberColumnFilter' },
        { field: 'utgstPercent', headerName: 'UTGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'utgst', headerName: 'UTGST', filter: 'agNumberColumnFilter' },
        { field: 'trackingNo', headerName: 'Tracking No', filter: true },
        // { field: 'orderPickedOn', headerName: 'Order Picked On', filter: true },
        { field: 'rnoteNo', headerName: 'RNote No', filter: true },
        { field: 'rnoteType', headerName: 'RNote Type', filter: true },
        { field: 'rnoteSupplyType', headerName: 'RNote Supply Type', filter: true },
        { field: 'rnoteDate', headerName: 'RNote Date', filter: true },
        { field: 'rnoteValue', headerName: 'RNote Value', filter: 'agNumberColumnFilter' },
        { field: 'rgstRate', headerName: 'RGST Rate', filter: 'agNumberColumnFilter' },
        { field: 'rtaxableValue', headerName: 'RTaxable Value', filter: 'agNumberColumnFilter' },
        { field: 'rigstPercent', headerName: 'RIGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rigst', headerName: 'RIGST', filter: 'agNumberColumnFilter' },
        { field: 'rcgstPercent', headerName: 'RCGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rcgst', headerName: 'RCGST', filter: 'agNumberColumnFilter' },
        { field: 'rsgstPercent', headerName: 'RSGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rsgst', headerName: 'RSGST', filter: 'agNumberColumnFilter' },
        { field: 'rutgstPercent', headerName: 'RUTGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rutgst', headerName: 'RUTGST', filter: 'agNumberColumnFilter' },
        { field: 'rirn', headerName: 'RIRN', filter: true },
        { field: 'rirnDate', headerName: 'RIRN Date', filter: true },
        { field: 'tcs', headerName: 'TCS', filter: 'agNumberColumnFilter' },
        { field: 'tcsRate', headerName: 'TCS Rate', filter: 'agNumberColumnFilter' },
        { field: 'tigst', headerName: 'TIGST', filter: 'agNumberColumnFilter' },
        { field: 'tcgst', headerName: 'TCGST', filter: 'agNumberColumnFilter' },
        { field: 'tsgst', headerName: 'TSGST', filter: 'agNumberColumnFilter' },
        { field: 'tutgst', headerName: 'TUTGST', filter: 'agNumberColumnFilter' },
        { field: 'taxableBankCharges', headerName: 'Taxable Bank Charges', filter: 'agNumberColumnFilter' },
        { field: 'bigst', headerName: 'BIGST', filter: 'agNumberColumnFilter' },
        { field: 'bcgst', headerName: 'BCGST', filter: 'agNumberColumnFilter' },
        { field: 'bsgst', headerName: 'BSGST', filter: 'agNumberColumnFilter' },
        { field: 'butgst', headerName: 'BUTGST', filter: 'agNumberColumnFilter' }
    ];

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        minWidth: 150,
    }), []);

    const handleExportCSV = () => {
        exportToCSV(rowData, 'buyerorders');
    }

    const handleExportExcel = () => {
        exportToExcel(rowData, 'buyerorders');
    }

    const handleExportPDF = () => {
        exportToPDF(rowData, 'buyerorders');
    }

    return (
        <div>
            <h1>All Orders</h1>
            <div className="mb-4">
                <button className='btn button-product mr-2' onClick={handleExportCSV}>Export to CSV</button>
                <button className='btn button-product mr-2' onClick={handleExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>
            
            <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50, 100]}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    );
}

export default BuyerOrders;