import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import './feedback.css';
const common = require('../utils/Common');
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ClientTable = () => {
  const navigate = useNavigate();
  const [sellerData, setSellerData] = useState([]);
  const [itemsPerPage] = useState(10);
  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getSellerDetails();
    }
  };
  const getSellerDetails = async () => {
    const parameters = {
      'Start_Date': $('#Date-from').val(),
      'End_Date': $('#Date-to').val(),
    };
    let response = await common.apiRequest('post', '/admin/seller-product-service-approve', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    
    const transformedData = data.flatMap(seller => {
      const rows = [];
      
      if (seller.non_approved_products > 0) {
        rows.push({
          ...seller,
          vendorResponse: 'Product',
          notApproved: seller.non_approved_products,
          rowType: 'product'
        });
      }
      
      if (seller.non_approved_logistics_services > 0) {
        rows.push({
          ...seller,
          vendorResponse: 'Logistics Service',
          notApproved: seller.non_approved_logistics_services,
          rowType: 'logistics'
        });
      }
      
      if (seller.non_approved_normal_services > 0) {
        rows.push({
          ...seller,
          vendorResponse: 'Normal Service',
          notApproved: seller.non_approved_normal_services,
          rowType: 'service'
        });
      }      
      return rows;
    });
    
    setSellerData(transformedData);
  };

  const movePage = (path, sellerId) => {
    navigate(path,{state:sellerId});
}

  const columnDefs = useMemo(() => [
    { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
    { headerName: "User Id", field: "reg_id", width: 150 },      
    { headerName: "First Name", field: "first_name", width: 200 },
    { headerName: "Middle Name", field: "middle_name", width: 150 },
    { headerName: "Last Name", field: "last_name", width: 150 },
    { headerName: "Type", field: "vendorResponse", width: 200 },
    { headerName: "Not Approved", field: "notApproved", width: 150 },
    { 
      headerName: "Created At", 
      width: 150,
      valueGetter: (params) => {
        switch(params.data.rowType) {
          case 'logistics':
            return params.data.logistics_service_created_at;
          case 'service':
            return params.data.service_created_at;
          case 'product':
            return params.data.product_created_at;
          default:
            return '';
        }
      },
      valueFormatter: (params) => {
        return params.value ? params.value.split('T')[0] : '';
      }
    },
    { 
      headerName: "Action", 
      field: "adminResponseDate", 
      cellRenderer: (params) => (
        <button 
        className='btn btn-primary btn-sm' 
        onClick={() => {
          const targetUrl = params.data.rowType === 'product' 
            ? '/sellers-products'
            : '/seller-service-approve';
          movePage(targetUrl, { seller_id: params.data.seller_id, rowType: params.data.rowType });
        }}
      >
        View Details
      </button>
      
      ),
      width: 150 
    }
  ], []);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }), []);

  return (
    <>
      <div className="col-md-12">
        <Form className='row'>
          <FormGroup className='col-md-4 row'>
            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
            <input 
              type='date' 
              defaultValue={moment().startOf('month').format('YYYY-MM-DD')} 
              onChange={getSellerDetails} 
              className="form-control col-md-5" 
              id="Date-from" 
              name="Date-from"
            />
          </FormGroup>
          <FormGroup className='col-md-4 row'>
            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
            <input 
              type='date' 
              defaultValue={moment().endOf('month').format('YYYY-MM-DD')} 
              onChange={getSellerDetails} 
              className="form-control col-md-5" 
              id="Date-to" 
              name="Date-to"
            />
          </FormGroup>
        </Form>
      </div>
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={sellerData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={itemsPerPage}
        />
      </div>
    </>
  );
};

export default ClientTable;