import React, { useEffect, useState ,useMemo} from 'react';
// import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, NavLink } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter ,Table} from 'reactstrap';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Sellers = () => {
    let navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [paymentOrders, setPaymentOrders] = useState([]);
    const [finalPaymentOrders, setFinalPaymentOrders] = useState([]);
    const [paymentForSeller, setPaymentForSeller] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const toggle = (orders = []) => {
        setModal(!modal);
        let finalPaymentOrders = [];
        orders.map((order) => {
            let orderItemIds = [];
            let paymentAmount = 0;
            let delivered = 0;
            order.order_items.map((data) => {
                if (data.order_status == 'delivered' && !data.seller_payment_status) {
                    if (paymentForSeller == 0) {
                        setPaymentForSeller(data.seller_id)
                    }
                    delivered = 1;
                    orderItemIds.push(data.id);
                    paymentAmount += parseInt(data.net_payment);
                }
            })
            if (delivered) {
                finalPaymentOrders.push({
                    orderId: order.id,
                    orderNo: order.order_id,
                    orderItemIds: orderItemIds.join(','),
                    paymentAmount: paymentAmount
                })
            }
        })
        setPaymentOrders(finalPaymentOrders);
        if (finalPaymentOrders.length == 0 && !modal) {
            alert('Nothing to pay');
            setModal(modal);
        }
    }
    const [sellersData, setSellersData] = useState([]);
	const getSellers = async () => {
		let data = {
			"user_type" : 'seller',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
		}
		let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setSellersData(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const movePage = (path, sellerId) => {
        navigate(path,{state:sellerId});
    }

    const setTdsCommission = async (id, seller_id) => {
        let value = $('#'+id).val();
        let data = {
            'update': id,
            'value': value,
            'seller_id': seller_id
        }
        let response = await common.apiRequest('post', '/admin/update-seller-tds-commission', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const addPayment = async (order, e) => {
        let finalPayment = finalPaymentOrders;
        if (e.target.checked) {
            finalPayment.push(order);
        } else {
            finalPayment = finalPayment.filter(function( obj ) {
                return obj.orderId !== order.orderId;
            });
        }
        setFinalPaymentOrders(finalPayment);
    }

    const makePayment = async (sellerId) => {
        if (finalPaymentOrders == 0) {
            alert("Please select one order");
            return;
        }
        let paymentAmount = 0;
        let orderItemIds = "";
        finalPaymentOrders.map((payment) => {
            orderItemIds = (orderItemIds == "" ? payment.orderItemIds : (orderItemIds + "," + payment.orderItemIds));
            paymentAmount += parseInt(payment.paymentAmount);
        })
        let data = {
            'action': 'create',
            'seller_id': sellerId,
            'order_item_ids': orderItemIds,
            'order_payment_amount': paymentAmount,
            'payment_status': 'done'
        }
        let response = await common.apiRequest('post', '/seller/crud-seller-payments', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                window.location.href = "/sellers";
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
        checkAccess();
	}, []);
    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
      };
    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            getSellers();
        }
      }

      const handleAccept = async (id) => {
        const data = {
            action: "update",
            id: id,
            pan_status: "1",
            user_type: 'buyer'
        };
        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getSellers();
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
        handleCloseModal();
    };
    
      const handleReject = async(id) => {
        const data = {
            action: "update",
            id:id,
            pan_status: "0",
            "user_type" : 'buyer'
          };
          try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getSellers();
            } else {
              alert(response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }    handleCloseModal();
      };
      const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { field: 'reg_id', headerName: 'User Id', filter: 'agTextColumnFilter' },
        { field: 'first_name', headerName: 'First Name', filter: 'agTextColumnFilter' },
        { field: 'middle_name', headerName: 'Middle Name', filter: 'agTextColumnFilter' },
        { field: 'last_name', headerName: 'Last Name', filter: 'agTextColumnFilter' },
        { field: 'seller_business_name', headerName: 'Service Vendor Business Name', filter: 'agTextColumnFilter' },
        { field: 'seller_pan_no', headerName: 'PAN No', filter: 'agTextColumnFilter' },
        { field: 'seller_gst', headerName: 'GST No', filter: 'agTextColumnFilter' },
        { field: 'totalPurchases', headerName: 'Total Shopping Amount', filter: 'agTextColumnFilter' },
        // { field: 'totalTaxablePurchases', headerName: 'Total Taxable Shopping Amount', filter: 'agTextColumnFilter' },
        { field: 'totalSales', headerName: 'Total Sales Amount', filter: 'agTextColumnFilter' },
        // { field: 'totalTaxableSales', headerName: 'Total Taxable Amount', filter: 'agTextColumnFilter' },
        { field: 'seller_email', headerName: 'Email', filter: 'agTextColumnFilter' },
        { field: 'mobile_phone', headerName: 'Mobile Phone', filter: 'agTextColumnFilter' },
        { field: 'bank_account_no', headerName: 'Bank Account No', filter: 'agTextColumnFilter' },
        { field: 'bank_name', headerName: 'Bank Name', filter: 'agTextColumnFilter' },
        { field: 'ifsc_code', headerName: 'IFSC Code', filter: 'agTextColumnFilter' },
        { field: 'nominee_name', headerName: 'Nominee Name', filter: 'agTextColumnFilter' },
        { field: 'referrerBY', headerName: 'Referred By', filter: 'agTextColumnFilter' },
        { field: 'user_address', headerName: 'Address', filter: 'agTextColumnFilter' },
        { field: 'seller_Point_Contact', headerName: 'Point of Contact', filter: 'agTextColumnFilter' },
        {
            field: 'tds',
            headerName: 'TDS',
            cellRenderer: params => {
                return (
                    <div>
                        <input 
                            type='text' 
                            id="tds" 
                            defaultValue={params.data.seller_tds} 
                            placeholder='Enter TDS'
                            style={{ width: '100px', marginRight: '5px' }}
                        />
                        <Button 
                            color="primary" 
                            size="sm" 
                            onClick={() => setTdsCommission('tds', params.data.id)}
                        >
                            Save
                        </Button>
                    </div>
                );
            }
        },
        {
            field: 'commission',
            headerName: 'Commission',
            cellRenderer: params => {
                return (
                    <div>
                        <input 
                            type='text' 
                            id="commission" 
                            defaultValue={params.data.seller_commission} 
                            placeholder='Enter Commission'
                            style={{ width: '100px', marginRight: '5px' }}
                        />
                        <Button 
                            color="primary" 
                            size="sm" 
                            onClick={() => setTdsCommission('commission', params.data.id)}
                        >
                            Save
                        </Button>
                    </div>
                );
            }
        },
        {
            field: 'orders',
            headerName: 'Orders',
            cellRenderer: params => {
                return (
                    <center>
                        <Button 
                            color="primary" 
                            size="sm" 
                            onClick={() => movePage('/sellers-buyers-orders', params.data.id)}
                        >
                            All Orders
                        </Button>
                    </center>
                );
            }
        },
        {
            field: 'products',
            headerName: 'Products',
            cellRenderer: params => {
                return (
                    <center>
                        <Button 
                            color="primary" 
                            size="sm" 
                            onClick={() => movePage('/sellers-products', {seller_id: params.data.id})}
                        >
                            All Products
                        </Button>
                    </center>
                );
            }
        },
        {
            field: 'services',
            headerName: 'services',
            cellRenderer: params => {
                return (
                    <center>
                        <Button 
                            color="primary" 
                            size="sm" 
                            onClick={() => movePage('/seller-service-approve', {seller_id: params.data.id})}
                        >
                            All service
                        </Button>
                    </center>
                );
            }
        },
        {
            field: 'pan_image',
            headerName: 'Pan Image',
            cellRenderer: params => {
                return (
                    <Button 
                        color="primary" 
                        onClick={() => handleShowModal(params.data)}
                    >
                        View Image
                    </Button>
                );
            }
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 150,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);
    const data = sellersData.map((item, index) => ({
        "S.no": index + 1,
        "User Id": item.reg_id || "N/A",  
        "First Name": item.first_name || "N/A",  
        "Middle Name": item.middle_name || "N/A",
        "Last Name": item.last_name || "N/A",  
        "Service Vendor Business Name": item.seller_business_name || "N/A",  
        "PAN No": item.pan_no || "N/A",  
        "GST No": item.gst_no || "N/A",  
        "Total Shopping Amount": item.totalPurchases || 0,  
        "Total Sales Amount": item.totalSales || 0,  
        "Email": item.email || "N/A",  
        "Mobile Phone": item.mobile_phone || "N/A",  
        "Bank Account No": item.bank_account_no || "N/A",  
        "Bank Name": item.bank_name || "N/A",  
        "IFSC Code": item.ifsc_code || "N/A",  
        "Nominee Name": item.nominee_name || "N/A",  
        "Referred By": item.referrerBY || "N/A",  
        "Address": item.user_address || "N/A",  
        "Point of Contact": item.seller_Point_Contact || "N/A",  
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'seller');
}

const handleExportExcel = () => {
    exportToExcel(data, 'seller');
}

const handleExportPDF = () => {
    exportToPDF(data, 'seller');
}
    return (
        <>
        <h1>All Sellers</h1><br/>
        <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getSellers} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                                <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getSellers} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    </div>
 <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={sellersData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
        <Modal isOpen={modal} toggle={() => toggle([])} size="lg">
            <ModalHeader toggle={() => {toggle([])}}>Make Payment</ModalHeader>
            <ModalBody>
                {
                    paymentOrders.map((order) => {
                        return (
                            <>
                            <input type='checkbox' onClick={(e) => addPayment(order, e)}/>&nbsp;&nbsp;
                            <label>{order.orderNo} (Amount Rs - {order.paymentAmount})</label>
                            <br/>
                            </>
                        )
                    })
                }
                <Button className="mt-3" color='primary' size='sm' onClick={() => makePayment(paymentForSeller)}>Pay</Button>
            </ModalBody>
        </Modal>
        <Modal isOpen={showModal} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>User PAN Image</ModalHeader>
                <ModalBody>
                    {selectedUser && (
                        <img
                            src={envConfig.api_url + selectedUser.user_pan_image}
                            style={{ width: '100%', height: 'auto' }}
                            alt="User PAN"
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => handleAccept(selectedUser.id)}>
                        Accept
                    </Button>
                    <Button color="danger" onClick={() => handleReject(selectedUser.id)}>
                        Reject
                    </Button>
                </ModalFooter>
            </Modal>
        
        </>
        
    )
}

export default Sellers;