import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
const common = require('../utils/Common');

const AdminUserTypes = () => {
    let navigate = useNavigate();
    const [userTypes, setUserTypes] = useState([]);
    const [editUserTypes, setEditUserTypes] = useState([]);
    const [modal, setModal] = useState(false);

    const toggle = (item) => {
        setModal(!modal);
        setEditUserTypes(item);
    }

    const crudAdminUserTypes = async (action, id) => {
        let data = {
			"action": action,
            "id": id
		}
        if (action == "create" || action == "update") {
            data.user_type = $('#user_type').val();
        }
		let response = await common.apiRequest('post', '/admin/crud-admin-user-types', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                if (action == "create" || action == "delete" || action == "update") {
                    alert(response.data.message);
                    crudAdminUserTypes('read', 0);
                    if (action == "create" || action == "update") {
                        toggle("");
                    }
                } else if (action == "read") {
                    setUserTypes(response.data.data)
                }
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const manageRights = (userTypeId) => {
        navigate('/user-type-rights/' + userTypeId);
    }

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname, 1);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudAdminUserTypes('read', 0);
        }
      }

    return (
        <>
        <h1>All Admin User Types</h1><br/>
        <button className='btn btn-primary' style={{float: 'right', marginRight: '50px', marginBottom: '20px'}} onClick={() => toggle("")}>Add New User Type</button>
        <Modal isOpen={modal} toggle={() => toggle("")}>
            <ModalHeader>{editUserTypes ? "Update" : "Add New"} User Type</ModalHeader>
            <ModalBody>
                <input type='text' id="user_type" placeholder='Enter User Type' defaultValue={editUserTypes && editUserTypes.user_type ? editUserTypes.user_type : ''}/>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => crudAdminUserTypes(editUserTypes && editUserTypes.id ? 'udpate' : 'create', editUserTypes && editUserTypes.id ? editUserTypes.id : 0)}>
                    Save
                </Button>{' '}
                <Button color="danger" onClick={() => toggle("")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>User Type</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    userTypes.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="7">
                                <center>No Data Available</center>
                            </td>
                        </tr>
                    :
                    userTypes.map((userType) => {
                        return (
                            <>
                                <tr>
                                    <td>{userType.user_type}</td>
                                    <td>
                                        <button className='btn btn-warning btn-sm' style={{marginRight: '20px'}} onClick={() => toggle(userType)}>Update</button>
                                        <button className='btn btn-danger btn-sm' style={{marginRight: '20px'}} onClick={() => crudAdminUserTypes('delete', userType.id)}>Delete</button>
                                        <button className='btn btn-success btn-sm' onClick={() => manageRights(userType.id)}>Manage Rights</button>
                                    </td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        </>
    )
}

export default AdminUserTypes;