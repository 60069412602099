const UPPERCASE_KEYWORDS = ['igst', 'cgst', 'sgst', 'utgst', 'cess', 'irn', 'tcs', 'gst'];

export const formatHeaderName = (key) => {
  let headerName = key.replace(/_/g, ' ');

  if (UPPERCASE_KEYWORDS.some(keyword => key.includes(keyword))) {
    const words = headerName.split(' ');
    headerName = words.map((word, index) => {
      if (index === 0) {
        return word.toUpperCase();
      } else {
        return word;
      }
    }).join(' ');
  } else {
    headerName = headerName.replace(/\b\w/g, (match) => match.charAt(0).toUpperCase() + match.slice(1));
  }

  return headerName;
};