import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { formatHeaderName } from '../../utils/formatHeaderName';
import { parseValue } from '../../utils/Common';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

export default function BankReports() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState({
    deposit_as_per_portal: '',
    payment_as_per_portal: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getReportData();
    }
  }

  const getReportData = async () => {
    var parameters = {
      'source': 'Admin',
      'report_name': 'bank_reco_reports',
      'start_date': $('#Date-from').val(),
      'end_date': $('#Date-to').val(),
    };
    let response = await common.apiRequest('post', '/reports/get-reports', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    const keysToExclude = ['order_item_id'];
        const cols = [
          {
              field: 'slNo',
              headerName: 'Sl No.',
              valueGetter: (params) => params.node.rowIndex + 1,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              width: 100
          },
          ...data.keys
              .filter(key => !keysToExclude.includes(key))
              .map(key => ({
                  field: key,
                  headerName: formatHeaderName(key),
                  filter: 'agTextColumnFilter',
                  valueFormatter: formatCellValue(),
                  ...(key.includes('price') || key.includes('value') || key.includes('gst') ? 
                      { width: 130, type: 'numericColumn', filter: 'agNumberColumnFilter' } : {}),
                  ...(key.includes('date') || key.includes('created_at') || key.includes('updated_at') ? 
                      { width: 160 } : {})
              }))
      ];
    setColumnDefs(cols);
    setRowData(data.reportData);
  }
  const ChangeIntDecimals = [
    'deposits','withdrawls','balance','deposit_as_per_portal','payment_as_per_portal','bank_charges'
  ];
    
  const formatCellValue = () => {
    return (params) => {
      const value = params.value;
      const field = params.colDef.field;
      if (value === null || value === undefined) {
        return '';
      }
  
      // Add specific check for GST fields
      if (field === 'gst_of_supplier' || field.includes('gst_number')) {
        return value; // Return GST number as-is
      }
  
      if (ChangeIntDecimals.includes(field)) {
        return parseValue(value, 'float');
      }
  
      if (field.includes('price') || field.includes('value') || 
          field.includes('amount') || field.includes('charges')) {
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        if (!isNaN(numValue)) {
          return numValue;
        }
      }
  
      if (field.includes('date') || field.includes('created_at') || field.includes('updated_at')) {
        if (value && !isNaN(Date.parse(value))) {
          const date = new Date(value);
          return date.toLocaleDateString();
        }
      }
      return value;
    };
  };
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    setSelectedRows(selectedData);
  };

  const handleUpdate = async () => {
    if (selectedRows.length !== 1) {
      alert("Please select exactly one row to update");
      return;
    }

    try {
      const parameters = {
        report_name: 'bank_reco_reports',
        order_item_id: selectedRows[0].order_item_id,
        update_data: {
          deposit_as_per_portal: updateData.deposit_as_per_portal,
          payment_as_per_portal: updateData.payment_as_per_portal
        }
      };
      const response = await common.apiRequest('post', '/reports/update-report', parameters);

      if (response.data && response.data.code === 'success') {
        alert('Record updated successfully');
        setIsModalOpen(false);
        getReportData();
      } else {
        alert('Failed to update record');
      }
    } catch (error) {
      console.error('Error updating record:', error);
      alert('Error updating record');
    }
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  }), []);

  const excludeFields = ['order_item_id'];

  const data = rowData.map(row => {
      const newRow = {};
      Object.keys(row)
          .filter(key => !excludeFields.includes(key))
          .forEach(key => {
              newRow[formatHeaderName(key)] = row[key];
          });
      return newRow;
  });
  const handleExportCSV = () => {
    exportToCSV(data, 'Bank report');
  }

  const handleExportExcel = () => {
    exportToExcel(data, 'Bank report');
  }

  const handleExportPDF = () => {
    exportToPDF(data, 'Bank report');
  }

  return (
    <>
      <div className="row report-margin">
        <div className='col-md-10'>
          <h3>Bank Reports</h3>
        </div>
        <div className="col-md-12">
          <Form className='row'>
            <FormGroup className='col-md-4 row'>
              <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
              <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
            </FormGroup>
            <FormGroup className='col-md-4 row'>
              <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
              <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
            </FormGroup>
          </Form>
        </div>
        <div className="col-md-12 button-container">
          <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
          <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
          <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
          {selectedRows.length === 1 && (
            <button
              className='btn button-product'
              onClick={() => setIsModalOpen(true)}
            >
              Update Selected
            </button>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>Update Record</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="deposit">Deposit as per Portal</Label>
            <Input
              type="number"
              id="deposit"
              value={updateData.deposit_as_per_portal}
              onChange={(e) => setUpdateData({ ...updateData, deposit_as_per_portal: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="payment">Payment as per Portal</Label>
            <Input
              type="number"
              id="payment"
              value={updateData.payment_as_per_portal}
              onChange={(e) => setUpdateData({ ...updateData, payment_as_per_portal: e.target.value })}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>Update</Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}