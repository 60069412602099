import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import $ from 'jquery';
import moment from 'moment';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter,Input } from 'reactstrap';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import { formatHeaderName } from '../utils/formatHeaderName';
import { parseValue } from '../utils/Common';
const common = require('../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

function SRRRReport() {
    const [rowData, setRowData] = useState([]);
    console.log(rowData)
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateData, setUpdateData] = useState({
      payment_id: '',
      payment_date: '',
      payment_id_vendor: '',
      date_vendor_payment: '',
      payment_id_others: '',
      date_others_payments: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            getReportData();
        }
    }

    const getReportData = async () => {
      var parameters = {
          'source': 'Admin',
          'report_name': 'srrr_reports',
          'start_date': $('#Date-from').val(),
          'end_date': $('#Date-to').val()
      };
      let response = await common.apiRequest('post', '/reports/get-reports', parameters);
      let data = (response.data && response.data.data) ? response.data.data : [];
      const keysToExclude = ['order_item_id'];
      const cols = [
        {
            field: 'slNo',
            headerName: 'Sl No.',
            valueGetter: (params) => params.node.rowIndex + 1,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 100
        },
        ...data.keys
            .filter(key => !keysToExclude.includes(key))
            .map(key => ({
                field: key,
                headerName: formatHeaderName(key),
                filter: 'agTextColumnFilter',
                valueFormatter: formatCellValue(),
                ...(key.includes('price') || key.includes('value') || key.includes('gst') ? 
                    { width: 130, type: 'numericColumn', filter: 'agNumberColumnFilter' } : {}),
                ...(key.includes('date') || key.includes('created_at') || key.includes('updated_at') ? 
                    { width: 160 } : {})
            }))
    ];
      setColumnDefs(cols);
      setRowData(data.reportData);
  }
  
  

const ChangeIntDecimals = [
'taxable_bank_charges','unit_price','tax_invoice_value','taxable_value','igst','cgst','sgst','utgst','cess',
'shipping_tax_invoice_value','shipping_taxable_value','sigst','scgst','ssgst','sutgst','scess','rnote_value',
'rtaxable_value','rigst','rcgst','rsgst','rutgst','rcess','tcs','tigst','tcgst','tsgst','tutgst','amount_receipt',
'bank_charges','taxable_bank_charges','bigst','bcgst','butgst','cartage','marketing_charges','commissions','discount',
'other_charges','net_payment_of_supplier','net_payment_to_vendor','net_payment_of_others','gross_profit','net_cash_flow'
];

const formatCellValue = () => {
  return (params) => {
    const value = params.value;
    const field = params.colDef.field;
    if (value === null || value === undefined) {
      return '';
    }

    // Add specific check for GST fields
    if (field === 'gst_of_supplier' || field.includes('gst_number')) {
      return value; // Return GST number as-is
    }

    if (ChangeIntDecimals.includes(field)) {
      return parseValue(value, 'float');
    }

    if (field.includes('price') || field.includes('value') || 
        field.includes('amount') || field.includes('charges')) {
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      if (!isNaN(numValue)) {
        return numValue;
      }
    }

    if (field.includes('date') || field.includes('created_at') || field.includes('updated_at')) {
      if (value && !isNaN(Date.parse(value))) {
        const date = new Date(value);
        return date.toLocaleDateString();
      }
    }
    return value;
  };
};

    const onSelectionChanged = (event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        setSelectedRows(selectedData);
      };

    const handleUpdate = async () => {
        if (selectedRows.length !== 1) {
          alert("Please select exactly one row to update");
          return;
        }
    
        try {
          const parameters = {
            report_name: 'srrr_reports',
            order_item_id: selectedRows[0].order_item_id,
            update_data: {
              payment_id: updateData.payment_id,
              payment_date: updateData.payment_date,
              payment_id_vendor: updateData.payment_id_vendor,
              date_vendor_payment: updateData.date_vendor_payment,
              payment_id_others: updateData.payment_id_others,
              date_others_payments: updateData.date_others_payments,
            }
          };
          const response = await common.apiRequest('post', '/reports/update-report', parameters);
    
          if (response.data && response.data.code === 'success') {
            alert('Record updated successfully');
            setIsModalOpen(false);
            getReportData();
          } else {
            alert('Failed to update record');
          }
        } catch (error) {
          console.error('Error updating record:', error);
          alert('Error updating record');
        }
      };
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    }), []);
    const excludeFields = ['order_item_id'];

      const data = rowData.map(row => {
          const newRow = {};
          Object.keys(row)
              .filter(key => !excludeFields.includes(key))
              .forEach(key => {
                  newRow[formatHeaderName(key)] = row[key];
              });
          return newRow;
      });
      const handleExportCSV = () => {
        exportToCSV(data, 'Srrr report');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'Srrr report');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'Srrr report');
    }
    return (
        <>      
            <div className="row report-margin">
                <div className='col-md-10'>
                    <h3>Srrr Reports</h3>
                </div>
                <div className="col-md-12">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="date_from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="date_from" name="date_from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="date_to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="date_to" name="date_to"></input>
                        </FormGroup>
                    </Form>      
                </div>
                {/* <CSVDownloader className="csvDownload"
                    filename={'Srrr report'}
                    data={() => csvData}
                >
                    Download Csv
                </CSVDownloader>
                <button className="btn csvDownload" onClick={exportPDF}>Download pdf</button> */}
                <div>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
         {selectedRows.length === 1 && (
            <button
              className='btn button-product'
              onClick={() => setIsModalOpen(true)}
            >
              Update Selected
            </button>
          )}
                </div>
            </div>
       
            <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                    onSelectionChanged={onSelectionChanged}
                />
            </div>
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>Update Record</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="payment_id">Payment ID</Label>
            <Input
              type="number"
              id="payment_id"
              value={updateData.payment_id}
              onChange={(e) => setUpdateData({ ...updateData, payment_id: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="payment_date">Payment Date</Label>
            <Input
                type="date"
                id="payment_date"
                value={updateData.payment_date}
                defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
                onChange={(e) => setUpdateData({ ...updateData, payment_date: e.target.value })}
            />
            </FormGroup>
          <FormGroup>
            <Label for="payment_id_vendor">Payment Id Vendor</Label>
            <Input
              type="number"
              id="payment_id_vendor"
              value={updateData.payment_id_vendor}
              onChange={(e) => setUpdateData({ ...updateData, payment_id_vendor: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="date_vendor_payment">Date Vendor Payment</Label>
            <Input
                type="date"
                id="date_vendor_payment"
              value={updateData.date_vendor_payment}
              defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
              onChange={(e) => setUpdateData({ ...updateData, date_vendor_payment: e.target.value })}            />
          </FormGroup>
          <FormGroup>
            <Label for="payment_id_others">Payment Id Others</Label>
            <Input
              type="number"
              id="payment_id_others"
              value={updateData.payment_id_others}
              onChange={(e) => setUpdateData({ ...updateData, payment_id_others: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="date_others_payments">Date Others Payments</Label>
            <Input
                type="date"
                id="date_others_payments"
              value={updateData.date_others_payments}
              defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
              onChange={(e) => setUpdateData({ ...updateData, date_others_payments: e.target.value })}            />
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>Update</Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
        </>
    )
}

export default SRRRReport;