import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useCSVReader } from 'react-papaparse';
import "./catogory.css";
import $ from 'jquery';
import { Card, CardBody, CardTitle, Button, Input, FormGroup, Label } from 'reactstrap';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
const common = require('../utils/Common');

const Category = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [getMainData, setGetMainData] = useState([]);
  const [parentCategory, setParentCategory] = useState(0);
  const [csvData, setCSVData] = useState([]);
  const { CSVReader } = useCSVReader();
  const [structuredData, setStructuredData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedName, setEditedName] = useState('');

  useEffect(() => {
    checkAccess();
  },[]);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getCategories(0);
    }
  }

  const getCategories = async (categoryId) => {
    if (categoryId) {
      setParentCategory(categoryId);
    }
    var parameters = {
      'action': 'read',
      'parent_category_id': categoryId,
      'source': "Admin"
    };
    let response = await common.apiRequest('post', '/admin/crud-product-category', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    const filteredData = response.data.data.filter(item => item.parent_category_id === 0);
    setGetMainData(filteredData);
    setCategories(data);
    structureData(response.data.data);
  }

  const addCategory = async () => {
    var parameters = {
      'action': 'create',
      'parent_category_id': parentCategory,
      'category_name': $('#category_name').val(),
      'source': "Admin"

    };
    let response = await common.apiRequest('post', '/admin/crud-product-category', parameters);
    if (response.status == 200) {
      if (response.data.code == "success") {
        alert(response.data.message);
        $('#category').val(0);
        $('#category_name').val("");
        setParentCategory(0);
        getCategories();
      } else {
        alert(response.data.message);
      }
    }
  }
  const structureData = (data) => {
    const structured = {};
    data.forEach(item => {
      if (item.parent_category_id === 0) {
        structured[item.id] = {
          id: item.id,
          name: item.category_name,
          subcategories: []
        };
      }
    });

    data.forEach(item => {
      if (item.parent_category_id !== 0 && structured[item.parent_category_id]) {
        structured[item.parent_category_id].subcategories.push({
          id: item.id,
          name: item.category_name
        });
      }
    });

    setStructuredData(structured);
  };
  const handleCSVUpload = async () => {
    if (csvData.length === 0) {
      alert('Please upload a CSV file first.');
      return;
    }

    try {
      const data = {
        action: "create",
        csvData: csvData,
        'source': "Admin"
      };
      const response = await common.apiRequest('post', '/admin/crud-product-category',data);
      if (response.data.code === "success") {
        alert(response.data.message);
        getCategories();
      }
      else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('API Request failed: ', error);
    }
  };
  const handleEdit = (id, isMainCategory, name) => {
    setEditingCategory({ id, isMainCategory });
    setEditedName(name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCategory(null);
    setEditedName('');
  };

  const handleSubmitEdit = async () => {
    if (editingCategory) {
      const data = {
        action: "update",
        id: editingCategory.id,
        category_name: editedName,
        'source': "Admin"
      };
      try {
        const response = await common.apiRequest('post', '/admin/crud-product-category', data);
        if (response.data.code === "success") {
          alert(response.data.message);
          getCategories();
          handleCloseModal();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('API Request failed: ', error);
      }
    }
  };
  const handleDelete = async (id) => {
    const data = {
      action: "delete",
      "id": id,
    };
   
    try {
      let response = await common.apiRequest('post', '/admin/crud-product-category', data);
      if (response.data.code === "success") {
        alert(response.data.message);
        getCategories();
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const category = Object.entries(structuredData).map(([id, category]) => {
    const subcategoryNames = category.subcategories.map(subcategory => subcategory.name).join(", ");
    return {
        "Main": category.name,
        "Sub": subcategoryNames
    };
});



const handleExportCSV = () => {
    exportToCSV(category, 'category');
}

const handleExportExcel = () => {
    exportToExcel(category, 'category');
}

const handleExportPDF = () => {
    exportToPDF(category, 'category');
}
  return (
    <>
    <div className='container'>
    <div className='col-md-12'>
        <h4 className='mainhead'>Add categories and sub categories</h4>
      </div>
     <Card>
    
    <CardBody>
      <CardTitle>Select and Enter Data</CardTitle>

      <FormGroup>
        <Label for="parent_category_id">Select Parent ID</Label>
        <select id="category" onChange={(e) => setParentCategory(e.target.value)}>
          <option value="0">Select an entry</option>
          {
            getMainData.map((category) => {
              return (
                <>
                  <option value={category.id}>{category.category_name}</option>
                </>
              )
            })
          }
        </select>
      </FormGroup>
      <FormGroup>
        <Label for="newEntry">Enter a sub entry</Label>
        <Input
          type='text' id="category_name" placeholder='Category Name'
        />
      </FormGroup>

      <Button color="primary" onClick={addCategory}>
          Add categories
      </Button>
    </CardBody>
  </Card>
  <Card className="mt-4">
        <CardBody>
          <CardTitle>Upload CSV</CardTitle>
          <p>
            Download CSV template:&nbsp;
            <a href='../../../productCategories.csv' download="categories.csv" style={{ 'color': 'blue', 'cursor': 'pointer' }}>
              Download
            </a>
          </p>
          <CSVReader
            onUploadAccepted={(results) => {
              if (results && results.data && results.data.length > 1) {
                const headers = results.data[0];
                if (headers.includes('main_category') && headers.includes('sub_category')) {
                  const processedData = results.data.slice(1)
                    .filter(row => row.some(cell => cell.trim() !== '')) 
                    .map(row => {
                      const rowData = {
                        main_category: row[headers.indexOf('main_category')],
                        sub_category: row[headers.indexOf('sub_category')]
                      };
                      if (!rowData.main_category || !rowData.sub_category) {
                        throw new Error('Empty fields detected');
                      }
                      return rowData;
                    });

                  if (processedData.length === 0) {
                    alert('The CSV file contains no valid data rows.');
                  } else {
                    setCSVData(processedData);
                    alert('CSV file processed. Click "Upload CSV Data" to submit.');
                  }
                } else {
                  alert('CSV file must contain "main_category" and "sub_category" columns.');
                }
              } else {
                alert('Invalid CSV file.');
              }
            }}
            onError={(error) => {
              if (error.message === 'Empty fields detected') {
                alert('The CSV file contains rows with empty fields. Please ensure all required fields are filled.');
              } else {
                alert('An error occurred while processing the CSV file.');
              }
            }}
          >
            {({ getRootProps }) => (
              <Button {...getRootProps()} color="secondary">
                Choose CSV File
              </Button>
            )}
          </CSVReader>

          <Button onClick={handleCSVUpload} color="primary">
            Upload CSV Data
          </Button>
        </CardBody>
        <div>
        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
            <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
            <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
        </div>
      </Card>
      <div className='row tablessd mt-4'>
        <div className='col-md-12'>
          <div className='table-responsive'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>Mian Actions</th>
                  <th>Sub Categories</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(structuredData).map(([id, category]) => (
                  <React.Fragment key={id}>
                    <tr>
                      <td>{category.name}</td>
                      <td>
                        <Button color="primary" size="sm" onClick={() => handleEdit(category.id, true, category.name)}>
                          Edit Main
                        </Button>
                        <Button color="danger" size="sm" onClick={() => handleDelete(category.id)}>Deleted</Button>
                      </td>
                      <td colSpan="2"></td>
                    </tr>
                    {category.subcategories.map((subcategory, index) => (
                      <tr key={`${id}-${index}`}>
                        <td colSpan="2"></td>
                        <td>{subcategory.name}</td>
                        <td>
                          <Button color="secondary" size="sm" onClick={() => handleEdit(subcategory.id, false, subcategory.name)}>
                            Edit Sub
                          </Button>
                          <Button color="danger" size="sm" onClick={() => handleDelete(subcategory.id)}>Deleted</Button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}

              </tbody>
            </Table>
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit {editingCategory?.isMainCategory ? 'Main' : 'Sub'} Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="editCategoryName">
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter category name"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmitEdit}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
     
   
    </>
  )
}

export default Category
