import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Pincodes = () => {
    let navigate = useNavigate();
    const [allPincodes, setAllPincodes] = useState([]);
    const [editPincode, setEditPincode] = useState({pincode: null, state: null, id: 0});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudPincodes(0, 'read');
        }
    }

    const addNewPincode = () => {
        toggle();
    }

    const crudPincodes = async (id, action) => {
        let data = {
            'id': id,
            'action': action,
            'source': 'admin'
        }
        if (action == 'create' || action == 'update') {
            data.pincode = $('#pincode').val();
            data.state = $('#state').val();
        }
        let response = await common.apiRequest('post', '/admin/crud-pincodes', data);
        if (response.data.code == 'success') {
            if (action == "read") {
                setAllPincodes(response.data.data);
            } else {
                alert(response.data.message);
                if (action != "delete") {
                    toggle();
                }
                crudPincodes(0, 'read');
            }
        } else {
            alert(response.data.error);
        }
    }

    const editPincodes = (data) => {
        setEditPincode({
            pincode: data.pincode,
            state: data.state,
            id: data.id
        });
        toggle();
    }

    return (
        <>
        <h1>All Pincodes</h1><br/>
        <button className='btn btn-primary btn-sm float-right mr-3 mb-5' onClick={addNewPincode}>Add New Pincode</button>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Pincode</th>
                    <th>State</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    allPincodes.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="3">
                                <center>No Pincodes Available</center>
                            </td>
                        </tr>
                    :
                    allPincodes.map((pincode) => {
                        return (
                            <>
                                <tr>
                                    <td>{pincode.pincode}</td>
                                    <td>{pincode.state}</td>
                                    <td>
                                        <button className='btn btn-warning btn-sm' onClick={() => editPincodes(pincode)}>Edit</button>
                                        <button className='btn btn-danger btn-sm' onClick={() => crudPincodes(pincode.id, 'delete')}>Delete</button>
                                    </td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Add Pincode</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Pincode</label>
                    </div>
                    <div className='col-md-10'>
                        <input type='text' className='form-control' id="pincode" defaultValue={editPincode.pincode}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'>
                        <label>State</label>
                    </div>
                    <div className='col-md-10'>
                        <input type='text' id="state" className='form-control' defaultValue={editPincode.state} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'></div>
                    <div className='col-md-10'>
                        <button className='btn btn-primary btn-sm' onClick={() => crudPincodes(editPincode.id ? editPincode.id: 0, editPincode.id ? 'update' : 'create')}>Submit</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        </>
    )
}

export default Pincodes;