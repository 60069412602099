import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import $ from 'jquery';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import moment from 'moment';
import { parseValue } from '../utils/Common';
import './catogory.css';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const BuyerOrders = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [allOrders, setAllOrders] = useState([]);
    console.log(allOrders,"uhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [orderStatuses, setOrderStatuses] = useState([]);
    console.log(orderStatuses)
    const [rowData, setRowData] = useState([]);
    console.log (rowData)
    const [selectedOrderData, setSelectedOrderData] = useState(null);
    const [orderTrackingData, setOrderTrackingData] = useState([]);
    const [showOrderTrackingModal, setShowOrderTrackingModal] = useState(false);


    console.log(rowData,"rowData")
    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            getAllOrders();
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        }
    }

    const getAllOrders = async () => {
        let data = {
            'user_id': location.state,
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/orders/get-orders', data);
        if (response && response.data && response.data.code === 'success') {
            setAllOrders(response.data.data);
            const formattedData = response?.data?.data 
            ? response.data.data.flatMap((order) =>                 
                order.order_items.map((orderItem) => ({
                    id : orderItem.id,
                    orderId: order.order_id,
                    userId: order.user_id,
                    saleType: orderItem.product_item ? "Online Product Sale" : orderItem.seller_service ? "Online Service Sale" : "Offline Product Sale",
                    name: orderItem.product_item ? orderItem.product_item.product.name : orderItem.seller_service ? orderItem.seller_service.service_name : "NA",
                    description: orderItem.product_item ? orderItem.product_item.product.description : orderItem.seller_service ? orderItem.seller_service.service_description : "NA",
                    orderPrice: parseValue(orderItem.order_price,'float'),
                    orderQuantity: orderItem.order_quantity,
                    orderStatus: orderItem.order_status,
                    orderDate: orderItem.created_at,
                    orderCompletedOn: orderItem.order_completed_on,
                    orderCancelledOn: orderItem.order_cancelled_on,
                    // paymentId: orderItem.payment_id,
                    // paymentStatus: orderItem.payment_status,
                    // sellerPaymentStatus: orderItem.seller_payment_status,
                    // bankCharges: orderItem.bank_charges,
                    // cartageCharges: orderItem.cartage_charges,
                    // marketingCharges: orderItem.marketing_charges,
                    // commissionCharges: orderItem.commission_charges,
                    // discount: orderItem.discount,
                    // otherCharges: orderItem.other_charges,
                    // tds: orderItem.tds,
                    // buyerPaymentStatus: orderItem.buyer_payment_status,
                    // offlineSaleId: orderItem.offline_sale_id,
                    // vendorPaymentStatus: orderItem.vendor_payment_status,
                    taxableAmount: parseValue(orderItem.taxable_amount,'float'),
                    taxPercent: parseValue(orderItem.tax_percent,'float'),
                    cgstPercent: parseValue(orderItem.cgst_percent,'float'),
                    cgst: parseValue(orderItem.cgst,'float'),
                    sgstPercent: parseValue(orderItem.sgst_percent,'float'),
                    sgst: parseValue(orderItem.sgst,'float'),
                    igstPercent: parseValue(orderItem.igst_percent,'float'),
                    igst: parseValue(orderItem.igst,'float'),
                    // orderShippedOn: orderItem.order_shipped_on,
                    // orderProcessedOn: orderItem.order_processed_on,
                    walletAmount: parseValue(orderItem.wallet_amount,'float'),
                    utgstPercent: parseValue(orderItem.utgst_percent,'float'),
                    utgst: parseValue(orderItem.utgst,'float'),
                    trackingNo: orderItem.tracking_no,
                    // orderPickedOn: orderItem.order_picked_on,
                    rnoteNo: orderItem.rnote_no,
                    rnoteType: orderItem.rnote_type,
                    rnoteSupplyType: orderItem.rnote_supply_type,
                    rnoteDate: orderItem.rnote_date,
                    rnoteValue: parseValue(orderItem.rnote_value,'float'),
                    rgstRate: parseValue(orderItem.rgst_rate,'float'),
                    rtaxableValue: parseValue(orderItem.rtaxable_value,'float'),
                    rigstPercent: parseValue(orderItem.rigst_percent,'float'),
                    rigst: parseValue(orderItem.rigst,'float'),
                    rcgstPercent: parseValue(orderItem.rcgst_percent,'float'),
                    rcgst: parseValue(orderItem.rcgst,'float'),
                    rsgstPercent: parseValue(orderItem.rsgst_percent,'float'),
                    rsgst: parseValue(orderItem.rsgst,'float'),
                    rutgstPercent: parseValue(orderItem.rutgst_percent,'float'),
                    rutgst: parseValue(orderItem.rutgst,'float'),
                    rirn: orderItem.rirn,
                    rirnDate: orderItem.rirn_date,
                    tcs: parseValue(orderItem.tcs,'float'),
                    tcsRate: parseValue(orderItem.tcs_rate,'float'),
                    tigst: parseValue(orderItem.tigst,'float'),
                    tcgst: parseValue(orderItem.tcgst,'float'),
                    tsgst: parseValue(orderItem.tsgst,'float'),
                    tutgst: parseValue(orderItem.tutgst,'float'),
                    taxableBankCharges: parseValue(orderItem.taxable_bank_charges,'float'),
                    bigst: parseValue(orderItem.bigst,'float'),
                    bcgst: parseValue(orderItem.bcgst,'float'),
                    bsgst: parseValue(orderItem.bsgst,'float'),
                    butgst: parseValue(orderItem.butgst,'float'),
                    order_item_photos: orderItem.order_item_photos,
                    order_trackings: orderItem.order_trackings,
                    returnReason: orderItem.return_reason,
                    replaceReason: orderItem.replace_reason,
                    cancelReason: orderItem.cancel_reason,
                    offlineOrder: orderItem?.offline_sale_product_datum,
                    sellerServiceOrder: orderItem?.seller_service,
                    productItemOrder: orderItem?.product_item,
                }))
            )
            : [];
            setRowData(formattedData);
        } else {
            setAllOrders([]);
            setRowData([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId) => {
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            alert(response.data.message.replaceAll("_", " "));
            getAllOrders();
        } else {
            alert(response.data.error, 'error');
        }
    }
    const handleShowModal = (user,orderData) => {
        setSelectedUser(user);
        setSelectedOrderData(orderData);
        setShowModal(true);
    };
    const handleShowOrderTrackingModal = (orderTrackingData) => {
        console.log(orderTrackingData,"awdawdadwawd");
        setOrderTrackingData(orderTrackingData);
        setShowOrderTrackingModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
        setSelectedOrderData(null);
    };
    const handleCloseOrderTrackingModal = () => {
        setShowOrderTrackingModal(false);
        setOrderTrackingData([]);
    };
    const columnDefs = [
        { field: 'orderId', headerName: 'Order ID', filter: true },
        { field: 'saleType', headerName: 'Sale Type', filter: true },
        { field: 'name', headerName: 'Name/Description', filter: true },
        { field: 'description', headerName: 'Description', filter: true },
        { field: 'orderPrice', headerName: 'Order Price', filter: 'agNumberColumnFilter' },
        { field: 'orderQuantity', headerName: 'Order Quantity', filter: 'agNumberColumnFilter' },
        { field: 'orderStatus', headerName: 'Order Status', filter: true },
        { 
            field: 'order_status', 
            headerName: 'Order Status',
            cellRenderer: params => {
                const getStatusOptions = () => {
                    if (params.data.productItemOrder) {
                        return orderStatuses.product || {};
                    } else if (params.data.offlineOrder) {
                        return orderStatuses.offline_product || {};
                    } else if (params.data.sellerServiceOrder) {
                        return orderStatuses.service || {};
                    }
                    return {};
                };

                const statusOptions = getStatusOptions();

                return (
                    <select 
                        id="order_status" 
                        value={params.value} 
                        className="form-control" 
                        onChange={(e) => changeOrderStatus(params.data.id, e.target.value, params.data.orderPrice, params.data.userId, params.value)} 
                        style={{width: '130px'}}
                    >
                        {Object.entries(statusOptions).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                );
            }
        },
        { field: 'orderDate', headerName: 'Order date', filter: true },
        { field: 'orderCompletedOn', headerName: 'Order Completed On', filter: true },
        { field: 'orderCancelledOn', headerName: 'Order Cancelled On', filter: true },
        // { field: 'paymentId', headerName: 'Payment ID', filter: true },
        // { field: 'paymentStatus', headerName: 'Payment Status', filter: true },
        // { field: 'bankCharges', headerName: 'Bank Charges', filter: 'agNumberColumnFilter' },
        // { field: 'cartageCharges', headerName: 'Cartage Charges', filter: 'agNumberColumnFilter' },
        // { field: 'marketingCharges', headerName: 'Marketing Charges', filter: 'agNumberColumnFilter' },
        // { field: 'commissionCharges', headerName: 'Commission Charges', filter: 'agNumberColumnFilter' },
        // { field: 'discount', headerName: 'Discount', filter: 'agNumberColumnFilter' },
        // { field: 'otherCharges', headerName: 'Other Charges', filter: 'agNumberColumnFilter' },
        // { field: 'tds', headerName: 'TDS', filter: 'agNumberColumnFilter' },
        // { field: 'buyerPaymentStatus', headerName: 'Buyer Payment Status', filter: true },
        // { field: 'offlineSaleId', headerName: 'Offline Sale ID', filter: true },
        // { field: 'vendorPaymentStatus', headerName: 'Vendor Payment Status', filter: true },
        { field: 'taxableAmount', headerName: 'Taxable Amount', filter: 'agNumberColumnFilter' },
        { field: 'taxPercent', headerName: 'Tax Percent', filter: 'agNumberColumnFilter' },
        { field: 'cgstPercent', headerName: 'CGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'cgst', headerName: 'CGST', filter: 'agNumberColumnFilter' },
        { field: 'sgstPercent', headerName: 'SGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'sgst', headerName: 'SGST', filter: 'agNumberColumnFilter' },
        { field: 'igstPercent', headerName: 'IGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'igst', headerName: 'IGST', filter: 'agNumberColumnFilter' },
        // { field: 'orderShippedOn', headerName: 'Order Shipped On', filter: true },
        // { field: 'orderProcessedOn', headerName: 'Order Processed On', filter: true },
        { field: 'walletAmount', headerName: 'Wallet Amount', filter: 'agNumberColumnFilter' },
        { field: 'utgstPercent', headerName: 'UTGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'utgst', headerName: 'UTGST', filter: 'agNumberColumnFilter' },
        { field: 'trackingNo', headerName: 'Tracking No', filter: true },
        // { field: 'orderPickedOn', headerName: 'Order Picked On', filter: true },
        { field: 'rnoteNo', headerName: 'RNote No', filter: true },
        { field: 'rnoteType', headerName: 'RNote Type', filter: true },
        { field: 'rnoteSupplyType', headerName: 'RNote Supply Type', filter: true },
        { field: 'rnoteDate', headerName: 'RNote Date', filter: true },
        { field: 'rnoteValue', headerName: 'RNote Value', filter: 'agNumberColumnFilter' },
        { field: 'rgstRate', headerName: 'RGST Rate', filter: 'agNumberColumnFilter' },
        { field: 'rtaxableValue', headerName: 'RTaxable Value', filter: 'agNumberColumnFilter' },
        { field: 'rigstPercent', headerName: 'RIGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rigst', headerName: 'RIGST', filter: 'agNumberColumnFilter' },
        { field: 'rcgstPercent', headerName: 'RCGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rcgst', headerName: 'RCGST', filter: 'agNumberColumnFilter' },
        { field: 'rsgstPercent', headerName: 'RSGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rsgst', headerName: 'RSGST', filter: 'agNumberColumnFilter' },
        { field: 'rutgstPercent', headerName: 'RUTGST Percent', filter: 'agNumberColumnFilter' },
        { field: 'rutgst', headerName: 'RUTGST', filter: 'agNumberColumnFilter' },
        { field: 'rirn', headerName: 'RIRN', filter: true },
        { field: 'rirnDate', headerName: 'RIRN Date', filter: true },
        { field: 'tcs', headerName: 'TCS', filter: 'agNumberColumnFilter' },
        { field: 'tcsRate', headerName: 'TCS Rate', filter: 'agNumberColumnFilter' },
        { field: 'tigst', headerName: 'TIGST', filter: 'agNumberColumnFilter' },
        { field: 'tcgst', headerName: 'TCGST', filter: 'agNumberColumnFilter' },
        { field: 'tsgst', headerName: 'TSGST', filter: 'agNumberColumnFilter' },
        { field: 'tutgst', headerName: 'TUTGST', filter: 'agNumberColumnFilter' },
        { field: 'taxableBankCharges', headerName: 'Taxable Bank Charges', filter: 'agNumberColumnFilter' },
        { field: 'bigst', headerName: 'BIGST', filter: 'agNumberColumnFilter' },
        { field: 'bcgst', headerName: 'BCGST', filter: 'agNumberColumnFilter' },
        { field: 'bsgst', headerName: 'BSGST', filter: 'agNumberColumnFilter' },
        { field: 'butgst', headerName: 'BUTGST', filter: 'agNumberColumnFilter' },
        {
            headerName: 'Order Images',
            cellRenderer: params => {
                const photos = params.data.order_item_photos;
                return photos && photos.length > 0 ? (
                    <Button color="primary" onClick={() => handleShowModal(photos,params.data)}>
                        View Images ({photos.length})
                    </Button>
                ) : null;
            }
        },
        {
            headerName: 'Order Tracking',
            cellRenderer: params => {
                console.log('Cell params:', params.data);
                const track = params.data.order_trackings;
                return track && track.length>0?(    
                    <Button color="primary" onClick={() => handleShowOrderTrackingModal(track)}>
                        View Tracking
                    </Button>
                ):null;
            }
        }
    ];

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        minWidth: 150,
    }), []);

    const handleExportCSV = () => {
        exportToCSV(rowData, 'buyerorders');
    }

    const handleExportExcel = () => {
        exportToExcel(rowData, 'buyerorders');
    }

    const handleExportPDF = () => {
        exportToPDF(rowData, 'buyerorders');
    }

    return (
        <div>
            <h1>All Orders</h1>
            <div className="col-md-12">
                <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getAllOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                        <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getAllOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                    </FormGroup>
                </Form>
            </div>
            <div className="mb-4">
                <button className='btn button-product mr-2' onClick={handleExportCSV}>Export to CSV</button>
                <button className='btn button-product mr-2' onClick={handleExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>
            
            <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50, 100]}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                />
            </div>
            <Modal isOpen={showModal} toggle={handleCloseModal} size="lg">
                <ModalHeader toggle={handleCloseModal}>Order Images</ModalHeader>
                <ModalBody>
                    {selectedUser && selectedUser.map((photo, index) => {
                        let imageLabel = '';
                        switch(photo.type) {
                            case 'seller_order':
                                imageLabel = 'Seller Order Image';
                                break;
                            case 'user_return':
                                imageLabel = 'Return Image';
                                break;
                            case 'user_cancel':
                                imageLabel = 'Cancellation Image';
                                break;
                            case 'user_replace':
                                imageLabel = 'Replacement Image';
                                break;
                            case 'deliver_order':
                                imageLabel = 'Delivery Image';
                                break;
                            default:
                                imageLabel = 'Order Image';
                        }
                        
                        return (
                            <div key={index} className="mb-4">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h6 className="mb-0">{imageLabel}</h6>
                                    {selectedOrderData && (
                                        <>
                                            {photo.type === 'user_return' && <h6 className="mb-0">Return Reason: {selectedOrderData.returnReason}</h6>}
                                            {photo.type === 'user_replace' && <h6 className="mb-0">Replace Reason: {selectedOrderData.replaceReason}</h6>}
                                            {photo.type === 'user_cancel' && <h6 className="mb-0">Cancel Reason: {selectedOrderData.cancelReason}</h6>}
                                        </>
                                    )}
                                    <small className="text-muted">Uploaded by: {photo.uploaded_via}</small>
                                </div>
                                <div className="border rounded p-2">
                                    <img
                                        src={envConfig.api_url + photo.photo}
                                        style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
                                        alt={imageLabel}
                                    />
                                </div>
                                {photo.description && (
                                    <p className="mt-2 text-muted small">{photo.description}</p>
                                )}
                            </div>
                        );
                    })}
                    {(!selectedUser || selectedUser.length === 0) && (
                        <div className="text-center text-muted">
                            No images available for this order
                        </div>
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={showOrderTrackingModal} toggle={handleCloseOrderTrackingModal} size="lg">
                <ModalHeader toggle={handleCloseOrderTrackingModal}>Order Tracking</ModalHeader>
                <ModalBody>
                                    {
                                        orderTrackingData ? orderTrackingData.map((track) => {
                                            if (track.status == 'cancel_requested') {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }) : ""
                                    }
                </ModalBody>
            </Modal>
        </div>
    );
}

export default BuyerOrders;