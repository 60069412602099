const axios = require('axios');
const envConfig = require('../config/envConfig');
const moment = require('moment');

exports.apiRequest = async (method, url, data) => {
	return await doApiRequest(method, url, data);
}

const doApiRequest = async (method, url, data) => {
	let headers = {};
	if (localStorage.getItem('token') && localStorage.getItem('token') != "undefined") {
		headers = {
			"x-access-token" : localStorage.getItem('token'),
			"source": "admin"
		}
		data.userId = localStorage.getItem('userId');
	}
	return await axios({
		method: method,
		url: envConfig.api_url + url,
		data: data,
		headers: headers
	}).then((result) => {
		if (result['headers']['access-token'] && localStorage.getItem('token') && localStorage.getItem('token') != result['headers']['access-token']) {
			localStorage.setItem('token', result['headers']['access-token']);
		}
		if (result['data']['code'] == "failed") {
			alert(result['data']['error']);
		}
		return result;
	}).catch((err) => {
		if (err.response && (err.response.status == 403 || err.response.status == 401)) {
			alert(err.response.statusText);
			if (localStorage.getItem('token')) {
				localStorage.removeItem('token');
			}
			window.location.href = "/login";
		} else if (err.message) {
			return err.message;
		}
		return err;
	});
}

exports.getSystemConfig = async (key) => {
	let data = {
		'action': 'read',
		'key': key
	}
	var response = await doApiRequest('post', '/admin/crud-system-config', data);
	if (response.status == 200) {
		if (response.data.code == 'success') {
			return response.data.data ? response.data.data[0]['value'] : null;
		} else {
			alert(response.data.error);
			return null;
		}
	} else {
		alert(response.data.error);
		return null;
	}
}

exports.checkAccessRights = async (path, superAdmin = 0) => {
	if (path == "/" || superAdmin) {
		return true;
	}
	var parameters = {
		'user_id': localStorage.getItem("userId"),
		'path': path,
		'super_admin': superAdmin
	};
	let response = await doApiRequest('post', '/admin/check-access-rights', parameters);
	console.log(response);
	if (response && response.data && response.data.code && response.data.code == "success" && response.data.data) {
		return true;
	} else {
		return false;
	}
}

exports.parseValue = (value, type) => {
	console.log(value);
	if (value === null || value === undefined || value === '') {
	  return '0.00';
	}
	const stringValue = typeof value === 'string' ? value : String(value);
  
	if (type === "float") {
	  return parseFloat(stringValue.replace(",","")).toFixed(2);
	}
	else if (type === "int") {
	  return parseInt(stringValue.replace(",","")).toFixed(2);
	}
	else if (isNaN(value)) {
	  return '0.00';
	}
	else {
	  return value;
	}
  }