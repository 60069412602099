import React, { useEffect, useState, useMemo } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { parseValue } from '../../utils/Common';
import { formatHeaderName } from '../../utils/formatHeaderName';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

function VendorReports() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const { CSVDownloader } = useCSVDownloader();
    const navigate = useNavigate();

    useEffect(() => {
        checkAccess();
      }, []);
    
      const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
          getReportData();
        }
    }

    const getReportData = async () => {
        var parameters = {
          'source':'Admin',
          'report_name': 'vendor_reports',
          'start_date': $('#Date-from').val(),
           'end_date': $('#Date-to').val()
        };
        let response = await common.apiRequest('post', '/reports/get-reports', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        const keysToExclude = ['order_item_id'];
        const cols = [
          {
              field: 'slNo',
              headerName: 'Sl No.',
              valueGetter: (params) => params.node.rowIndex + 1,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              width: 100
          },
          ...data.keys
              .filter(key => !keysToExclude.includes(key))
              .map(key => ({
                  field: key,
                  headerName: formatHeaderName(key),
                  filter: 'agTextColumnFilter',
                  valueFormatter: formatCellValue(),
                  ...(key.includes('price') || key.includes('value') || key.includes('gst') ? 
                      { width: 130, type: 'numericColumn', filter: 'agNumberColumnFilter' } : {}),
                  ...(key.includes('date') || key.includes('created_at') || key.includes('updated_at') ? 
                      { width: 160 } : {})
              }))
      ];
        setColumnDefs(cols);
        setRowData(data.reportData);
    }
    const ChangeIntDecimals = [
      'invoice_value','rate','taxable_value','igst','cgst','sgst','utgst','cess','unit_price','rnote_value','rtaxable_value','rigst','rcgst','rsgst','rutgst','rcess','tcs','tigst','tcgst','tsgst','tutgst','shipping_amount','shipping_taxable_amount','sigst','scgst','ssgst','sutgst','tcs',
    'amount_receipt','bank_charges','cartage','marketing_charges','commissions','discount','other_charges','tds','net_payment','net_payment_vendor'];
      
    const formatCellValue = () => {
      return (params) => {
        const value = params.value;
        const field = params.colDef.field;
        if (value === null || value === undefined) {
          return '';
        }
    
        // Add specific check for GST fields
        if (field === 'gst_of_supplier' || field.includes('gst_number')) {
          return value; // Return GST number as-is
        }
    
        if (ChangeIntDecimals.includes(field)) {
          return parseValue(value, 'float');
        }
    
        if (field.includes('price') || field.includes('value') || 
            field.includes('amount') || field.includes('charges')) {
          const numValue = typeof value === 'string' ? parseFloat(value) : value;
          if (!isNaN(numValue)) {
            return numValue;
          }
        }
    
        if (field.includes('date') || field.includes('created_at') || field.includes('updated_at')) {
          if (value && !isNaN(Date.parse(value))) {
            const date = new Date(value);
            return date.toLocaleDateString();
          }
        }
        return value;
      };
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
        // editable: true,
    }), []);
      const excludeFields = ['order_item_id'];

      const data = rowData.map(row => {
          const newRow = {};
          Object.keys(row)
              .filter(key => !excludeFields.includes(key))
              .forEach(key => {
                  newRow[formatHeaderName(key)] = row[key];
              });
          return newRow;
      });
    const handleExportCSV = () => {
        exportToCSV(data, 'Vendor report');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'Vendor report');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'Vendor report');
    }
    return (
        <>
        <div className="row report-margin">
        <div className='col-md-10'>
        <h3>Vendor Reports</h3>
        </div>
        <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
        <div className="col-md-12 button-container">
        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

        </div>
      </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorReports;