import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import './feedback.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

const common = require('../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Feedback = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [replyData, setReplyData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [feedbackReply, setFeedbackReply] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 
    const navigate = useNavigate();

    const toggle = () => setModal(!modal);
    const toggleAnother = () => setModalTwo(!modalTwo);

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            getFeedback();
        }
    }

    const getFeedback = async () => {
        const parameters = {
            source: 'admin',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/FeedBackDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setFeedbackData(data);
    };
    const invoiceId = replyData?.order_item?.order?.invoices?.[0]?.id;
    const invoiceNO = replyData?.order_item?.order?.invoices?.[0]?.invoice_no;

    const reply = async () => {
        const parameters = {
            feedback_id: replyData.id,
            feedback_reply: feedbackReply,
            invoice_id: invoiceId,
            feedback_reply_by: "Admin",
            source: "admin",
        };

        let response = await common.apiRequest('post', '/users/user-feedback', parameters);
        if (response.status === 200) {
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
                alert(response.data.error);
            }
            window.location.reload();
        }
    };

    const replyFeedback = (data) => {
        setReplyData(data);
        toggle();
    };

    const showFeedback = (data) => {
        setReplyData(data);
        toggleAnother();
    };

    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
        { headerName: "User Id", field: "user.reg_id", width: 150 },
        { 
            headerName: "Invoice No", 
            valueGetter: params => params.data?.order_item?.order?.invoices?.[0]?.invoice_no || "N/A", 
            width: 150 
        },        
        { headerName: "User feedback", field: "user_feedback", width: 200 },
        { headerName: "Date", field: "created_at", width: 150 },
        { headerName: "Category", field: "type", width: 150 },
        { headerName: "Response from vendor", field: "vendorResponse", width: 200 },
        { headerName: "Date of response", field: "vendorResponseDate", width: 150 },
        { headerName: "Response from Admin", field: "adminResponse", width: 200 },
        { headerName: "Date of response", field: "adminResponseDate", width: 150 },
        { 
            headerName: "Reply", 
            field: "reply", 
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => replyFeedback(params.data)}>View</button>
            ),
            width: 100 
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    const rowData = feedbackData.map((data, index) => {
        const vendorResponse = data.feedback_replys.find(reply => reply.feedback_reply_by === 'seller');
        const adminResponse = data.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin');

        return {
            ...data,
            vendorResponse: vendorResponse ? vendorResponse.feedback_reply : "No response",
            vendorResponseDate: vendorResponse ? vendorResponse.created_at : "N/A",
            adminResponse: adminResponse ? adminResponse.feedback_reply : "No response",
            adminResponseDate: adminResponse ? adminResponse.created_at : "N/A"
        };
    });
    const data = feedbackData.map((item, index) => ({
        "S.no": index + 1,
        "Invoice No": item?.order_item?.order?.invoices?.[0]?.invoice_no || "N/A",
        "User Feedback": item.user_feedback,
        "Date": item.created_at,
        "Category": item.type,
        "Response from Vendor": item.feedback_replys.find(reply => reply.feedback_reply_by === 'seller')?.feedback_reply || "No response",
        "Date of Vendor Response": item.feedback_replys.find(reply => reply.feedback_reply_by === 'seller')?.created_at || "N/A",
        "Response from Admin": item.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin')?.feedback_reply || "No response",
        "Date of Admin Response": item.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin')?.created_at || "N/A"
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'feedback');
}

const handleExportExcel = () => {
    exportToExcel(data, 'feedback');
}

const handleExportPDF = () => {
    exportToPDF(data, 'feedback');
}
    return (
        <>
            <div className='feedback-component'>
                <div>
                    <div className='feedback-header col-md-12'>
                        <h2>Users Feedback</h2>
                    </div>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getFeedback} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getFeedback} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                    </div>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={itemsPerPage}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Feedback reply</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="reply">Reply feedback</Label>
                            <Input
                                type='text'
                                id="feedback_reply"
                                value={feedbackReply}
                                onChange={(e) => setFeedbackReply(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                    <Button className='submit' onClick={reply}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Feedback;

